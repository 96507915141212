export const STATES_UF = [
  {
    nome: "Acre",
    sigla: "AC",
    capital: "Rio Branco",
    cepRanges: [[69900, 69999]],
  },
  {
    nome: "Alagoas",
    sigla: "AL",
    capital: "Maceió",
    cepRanges: [[57000, 57999]],
  },
  {
    nome: "Amapá",
    sigla: "AP",
    capital: "Macapá",
    cepRanges: [[68900, 68999]],
  },
  {
    nome: "Amazonas",
    sigla: "AM",
    capital: "Manaus",
    cepRanges: [
      [69000, 69299],
      [69400, 69899],
    ],
  },
  {
    nome: "Bahia",
    sigla: "BA",
    capital: "Salvador",
    cepRanges: [[40000, 48999]],
  },
  {
    nome: "Ceará",
    sigla: "CE",
    capital: "Fortaleza",
    cepRanges: [[60000, 63999]],
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
    capital: "Brasília",
    cepRanges: [[70000, 73699]],
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
    capital: "Vitória",
    cepRanges: [[29000, 29999]],
  },
  {
    nome: "Goiás",
    sigla: "GO",
    capital: "Goiânia",
    cepRanges: [[72800, 76799]],
  },
  {
    nome: "Maranhão",
    sigla: "MA",
    capital: "São Luís",
    cepRanges: [[65000, 65999]],
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
    capital: "Cuiabá",
    cepRanges: [[78000, 78899]],
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
    capital: "Campo Grande",
    cepRanges: [[79000, 79999]],
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
    capital: "Belo Horizonte",
    cepRanges: [[30000, 39999]],
  },
  { nome: "Pará", sigla: "PA", capital: "Belém", cepRanges: [[66000, 68899]] },
  {
    nome: "Paraíba",
    sigla: "PB",
    capital: "João Pessoa",
    cepRanges: [[58000, 58999]],
  },
  {
    nome: "Paraná",
    sigla: "PR",
    capital: "Curitiba",
    cepRanges: [[80000, 87999]],
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
    capital: "Recife",
    cepRanges: [[50000, 56999]],
  },
  {
    nome: "Piauí",
    sigla: "PI",
    capital: "Teresina",
    cepRanges: [[64000, 64999]],
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
    capital: "Rio de Janeiro",
    cepRanges: [[20000, 28999]],
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
    capital: "Natal",
    cepRanges: [[59000, 59999]],
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
    capital: "Porto Alegre",
    cepRanges: [[90000, 99999]],
  },
  {
    nome: "Rondônia",
    sigla: "RO",
    capital: "Porto Velho",
    cepRanges: [[76800, 76999]],
  },
  {
    nome: "Roraima",
    sigla: "RR",
    capital: "Boa Vista",
    cepRanges: [[69300, 69399]],
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
    capital: "Florianópolis",
    cepRanges: [[88000, 89999]],
  },
  {
    nome: "São Paulo",
    sigla: "SP",
    capital: "São Paulo",
    cepRanges: [[1000, 19999]],
  },
  {
    nome: "Sergipe",
    sigla: "SE",
    capital: "Aracaju",
    cepRanges: [[49000, 49999]],
  },
  {
    nome: "Tocantins",
    sigla: "TO",
    capital: "Palmas",
    cepRanges: [[77000, 77999]],
  },
];

export const MONTHS = [
  { name: "Janeiro", value: 1 },
  { name: "Fevereiro", value: 2 },
  { name: "Março", value: 3 },
  { name: "Abril", value: 4 },
  { name: "Maio", value: 5 },
  { name: "Junho", value: 6 },
  { name: "Julho", value: 7 },
  { name: "Agosto", value: 8 },
  { name: "Setembro", value: 9 },
  { name: "Outubro", value: 10 },
  { name: "Novembro", value: 11 },
  { name: "Dezembro", value: 12 },
];

export const WEEK_DAYS = [
  { name: "Domingo", value: 0 },
  { name: "Segunda", value: 1 },
  { name: "Terça", value: 2 },
  { name: "Quarta", value: 3 },
  { name: "Quinta", value: 4 },
  { name: "Sexta", value: 5 },
  { name: "Sábado", value: 6 },
];

export const SYSTEM_STATUS_OBJECT = {
  init: "Cliente em Potencial",
  status1: "Contato Inicial",
  status2: "Elaboração da Proposta Inicial",
  status3: "Acompanhamento do Cliente",
  status4: "Cliente Levemente Interessado",
  status5: "Cliente Moderadamente Interessado",
  status6: "Cliente Fortemente Interessado",
  status7: "Visita Técnica",
  status8: "Proposta e Negociação Final",
  status9: "Concretização da Venda",
  status10: "Aquisição de Dados e Documentos",
  status11: "Definição e Compra dos Equipamentos",
  status12: "Desenvolvimento do Projeto e Aprovação",
  status13: "Projeto Aprovado",
  status14: "Em Instalação do Sistema",
  status15: "Comissionamento da Instalação",
  status16: "Em Vistoria",
  status17: "Aguardando Substituição do Medidor",
  status18: "Sistema Concluído",
  status19: "Acompanhamento de Pós-Venda",
  status20: "Pós-venda Concluída",
  lost: "Perdido",
};

export const COMPLEMENTARY_VALUES_BAND_KINDS_TO_STRING = {
  fixedValue: "Valor Fixo",
  valueByKm: "Valor por Km",
  valueByKWp: "Valor por kWp",
  valueByWp: "Valor por Wp",
};

export const TRANSLATED_PROPOSAL_FINANCING_TYPE = {
  priceCash: "PRICE - IOF à vista",
  priceFinanced: "PRICE - IOF financiado",
  sacCash: "SAC - IOF à vista",
  sacFinanced: "SAC - IOF financiado",
};

export const TRANSLATED_INTEGRATOR_ROLE = {
  manager: "Administrador",
  supervisor: "Supervisor",
  seller: "Vendedor",
};

export const TRANSLATED_ORIGIN = {
  instagram: "Instagram",
  facebook: "Facebook",
  whatsapp: "WhatsApp",
  google: "Google",
  site: "Site",
  indication: "Indicação",
  other: "Outro",
  unknown: "Não informada",
};

export const TRANSLATED_ORIGIN_ENERGY_COMPANY = {
  event: "Evento",
  ...TRANSLATED_ORIGIN,
};

export const CRM_ROUTES = [
  "CrmModule",
  "Leads",
  "LeadDetails",
  "LeadDetailsMain",
  "LeadDetailsGeneralData",
  "LeadDetailsSystems",
  "LeadDetailsProposals",
  "LeadDetailsRequests",
  "SystemDetails",
  "ProposalDetails",
  "Negotiations",
  "Sold",
  "Schedule",
  "Tasks",
  "IntegratorUsers",
  "IntegratorsUserDetails",
  "ProposalSettings",
  "DefaultInformations",
  "ProposalTemplates",
  "DefaultValuesAndPermissions",
  "FinancingIntegrator",
  "BudgetDetails",
  "OrderDetails",

  "SizingCRM",
  "SizingComponentCRM",
  "SizingFastCRM",
  "ResultSizingCRM",
  "ProposalCRM",
  "KitsIntegratorCRM",
  "MountKitCRM",
  "ProposalValuesCRM",
  "SimulateFinancingCRM",
  "FinancingSimulationResultCRM",
  "ProposalPaybackCRM",
];

export const MAIN_STATUS = {
  new: "Novo",
  refused: "Recusado",
  requestStatus: "Em Andamento",
  finish: "Finalizado",
  lost: "Perdido",
};

export const TYPES_LINK = [
  { text: "Monofásico", valueNumber: 30, valueString: "monofasico" },
  { text: "Bifásico", valueNumber: 50, valueString: "bifasico" },
  { text: "Trifásico", valueNumber: 100, valueString: "trifasico" },
];

export const PURCHASE_TYPE_SEPARATED_SALE = "Produto Avulso";

export const PURCHASE_TYPE_CUSTOM_KIT = "Kit Personalizado";

export const PURCHASE_TYPE_KIT_EXPRESS = "Kit Express";

export const PURCHASE_TYPE_CLOSED_KIT = "Kit Fechado";

export const PURCHASE_TYPES = {
  [PURCHASE_TYPE_CUSTOM_KIT]: "mountKits",
  [PURCHASE_TYPE_SEPARATED_SALE]: "separatedSales",
  [PURCHASE_TYPE_CLOSED_KIT]: "closedKits",
  [PURCHASE_TYPE_KIT_EXPRESS]: "kitExpress",
};

export const PRODUCT_TYPES = {
  module: "Módulo",
  inverter: "Inversor",
  stringBox: "String Box",
  complementaryEquipment: "Outros equipamentos",
};

export const INVERTER_TYPE_GROUPS = {
  string: "Inversor String",
  micro: "Microinversor",
  // solaredge + otimizador
  solaredge: "SolarEdge - Inversor com Otimizadores de Potência",
};

export const INVERTER_TYPES = {
  string: "Inversor String",
  micro: "Microinversor",
  solaredge: "SolarEdge - Inversor",
  otimizador: "SolarEdge - Otimizador de Potência",
};

export const MARKETPLACE_CATEGORIES = [
  {
    text: "Análise Pieta",
    value: "inAnalysis",
    required: false,
    fixed: true,
    sequence: 1,
  },
  {
    text: "Aguardando pagamento",
    value: "waitingPayment",
    required: true,
    fixed: false,
    sequence: 2,
  },
  {
    text: "Pagamento confirmado",
    value: "paymentConfirmed",
    required: true,
    fixed: false,
    sequence: 3,
  },
  {
    text: "Pedido faturado",
    value: "invoiced",
    required: false,
    fixed: false,
    sequence: 4,
  },
  {
    text: "Pedido em separação",
    value: "inSeparation",
    required: false,
    fixed: false,
    sequence: 5,
  },
  {
    text: "Aguardando envio",
    value: "waitingShipment",
    required: false,
    fixed: false,
    sequence: 6,
  },
  {
    text: "Pedido em transporte",
    value: "inTransport",
    required: true,
    fixed: false,
    sequence: 7,
  },
  {
    text: "Pedido entregue",
    value: "delivered",
    required: false,
    fixed: false,
    sequence: 8,
  },
  {
    text: "Pedido finalizado",
    value: "finished",
    required: true,
    fixed: true,
    sequence: 9,
  },
  {
    text: "Pedido cancelado",
    value: "refused",
    required: true,
    fixed: true,
    sequence: null,
  },
];

export const KITS_SEARCH_POWER_FILTER = {
  PERCENTUAL_POWER_OVER: 30,
  PERCENTUAL_POWER_UNDER: 20,
  DELIMITER: 30,
};

export const HISTORY_CHANGES_HEADER_MAPPER_REQUEST = {
  "Alteração de dados de entrega": "Entrega",
  "Alteração de dados de faturamento": "Faturamento",
  "Alteração de status": "Status",
  "Alteração de orçamento para pedido": "Orçamento para pedido",
  "Alteração de desconto/acréscimo": "Desconto/Acréscimo",
  "Alteração de status do pagamento": "Pagamento",
  "Alteração de responsável": "Responsável",
  "Alteração de equipamento": "Equipamento",
  "Alteração geral": "Outras",
};

export const HISTORY_CHANGES_HEADER_MAPPER_PRODUCT = {
  Estoque: "Estoque",
  "Preço base": "Preço base",
  Código: "Código",
  "Disponibilidade de Venda": "Disponibilidade de Venda",
  Nome: "Nome",
  "Alteração geral": "Outras",
};

export const PRECIFICATION_CHARGE_TYPE = {
  company: "minha empresa",
  lead: "cliente final",
};

const sortColors = function (hexArray) {
  const vals = {
    red: [],
    blue: [],
    green: [],
  };
  const colors = hexArray.map((color) => {
    const hex = color.substring(1);
    /* Get the RGB values to calculate the Hue. */
    const red = parseInt(hex.substring(0, 2), 16) / 255;
    const green = parseInt(hex.substring(2, 4), 16) / 255;
    const blue = parseInt(hex.substring(4, 6), 16) / 255;

    /* Getting the Max and Min values for Chroma. */
    const max = Math.max.apply(Math, [red, green, blue]);
    const min = Math.min.apply(Math, [red, green, blue]);

    /* constiables for HSV value of hex color. */
    const chroma = max - min;
    let hue = 0;
    const val = max;
    let sat = 0;
    if (val > 0) {
      /* Calculate Saturation only if Value isn't 0. */
      sat = chroma / val;
      if (sat > 0) {
        if (red == max) {
          hue = 60 * ((green - min - (blue - min)) / chroma);
          if (hue < 0) {
            hue += 360;
          }
          vals.red.push(hue);
        } else if (green == max) {
          hue = 120 + 60 * ((blue - min - (red - min)) / chroma);
          vals.green.push(hue);
        } else if (blue == max) {
          hue = 240 + 60 * ((red - min - (green - min)) / chroma);
          vals.blue.push(hue);
        }
      }
    }
    return {
      hex,
      chroma,
      hue,
      sat,
      val,
      luma: 0.3 * red + 0.59 * green + 0.11 * blue,
      red,
      green,
      blue,
    };
  });
  return colors
    .sort((a, b) => (a.luma > b.luma ? -1 : 1))
    .map((c) => "#" + c.hex);
};

export const STATUS_COLOR_NEW = "#F07707";
export const STATUS_COLOR_FINISHED = "#198F3A";
export const STATUS_COLOR_REFUSED = "#F13838";

export const REQUEST_STATUS_COLORS = [
  "#009900",
  "#F5CD47",
  "#FF9900",
  "#006600",
  "#946F00",
  "#DD8603",
  "#CC0000",
  "#9F8FEF",
  "#579DFF",
  "#990033",
  "#6D51F4",
  "#005DDE",
  "#E774BB",
  "#8590A2",
  "#000000",
  "#AE4787",
  "#5B677B",
  STATUS_COLOR_NEW,
  STATUS_COLOR_FINISHED,
  STATUS_COLOR_REFUSED,
  "#E0E0E0",
];

export const STATUS_COUPON_COLOR_ACTIVE = "#198F3A";
export const STATUS_COUPON_COLOR_INACTIVE = "#717171";
export const STATUS_COUPON_COLOR_EXPIRED = "#E70C0CB2";

export const STATUS_COUPON_TEXT_ACTIVE = "ativado";
export const STATUS_COUPON_TEXT_INACTIVE = "desativado";
export const STATUS_COUPON_TEXT_EXPIRED = "expirado";

export const STATUS_COUPON_COLOR_LIST = [
  [STATUS_COUPON_TEXT_ACTIVE, STATUS_COUPON_COLOR_ACTIVE],
  [STATUS_COUPON_TEXT_INACTIVE, STATUS_COUPON_COLOR_INACTIVE],
  [STATUS_COUPON_TEXT_EXPIRED, STATUS_COUPON_COLOR_EXPIRED],
];

export const COUPON_CLOSED_KITS = "closedKits";
export const COUPON_MOUNT_KITS = "mountKitSales";
export const COUPON_SEPARATED_SALES = "separatedSales";
export const COUPON_KIT_EXPRESS = "kitExpress";

export const COUPON_PRODUCT_TYPES = [
  ["Kits Fechados", COUPON_CLOSED_KITS],
  ["Kits Personalizados", COUPON_MOUNT_KITS],
  ["Produtos Avulsos", COUPON_SEPARATED_SALES],
  ["Kit Express", COUPON_KIT_EXPRESS],
];

export const COUPON_DISCOUNT_FIXED = "fixed";
export const COUPON_DISCOUNT_PERCENTAGE = "percentage";
export const COUPON_DISCOUNT_TYPES = [
  ["% Percentual", COUPON_DISCOUNT_PERCENTAGE],
  ["R$ Fixo", COUPON_DISCOUNT_FIXED],
];

export const COUPON_LIMITED_FOR_USE_UNLIMITED = "unlimited";
export const COUPON_LIMITED_FOR_USE_COMPANY = "company";
export const COUPON_LIMITED_FOR_USE_TYPES = [
  ["Ilimitado", COUPON_LIMITED_FOR_USE_UNLIMITED],
  ["Qtd. específica por empresa de energia", COUPON_LIMITED_FOR_USE_COMPANY],
];

export const PERSON_TYPES = {
  CNPJ: "cnpj",
  CPF: "cpf",
};

export const MAX_FILE_SIZE = {
  IMAGE: 2e6,
  IMAGE_LARGE: 5e6,
  DOCUMENT: 10e6,
};
