import Icons from "@/constants/Icons";

export default [
  {
    path: "/ecommerce",
    name: "Ecommerce",
    redirect: "/ecommerce/general",
    component: () => import("@/views/GroupRouter.vue"),
    meta: {
      icon: Icons.ECOM,
      title: "E-commerce",
      local: ["navBar"],
      isGroup: true,
      actions: ["ecommerce"],
      permissions: ["admin", "staff"],
      staffPermissions: ["configuration.ecommerce"],
    },
    children: [
      {
        path: "general",
        name: "EcommerceGeneral",
        meta: {
          title: "Geral",
          icon: Icons.GENERAL,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.geral"],
        },
        component: () => import("@/views/ecommerce/PageEcommerceGeneral.vue"),
      },
      {
        path: "settings/email",
        name: "EcommerceEmail",
        meta: {
          title: "E-mail",
          icon: Icons.EMAIL,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.email"],
        },
        component: () => import("@/views/settings/emails/Email.vue"),
      },
      {
        path: "visual-themes",
        name: "EcommerceVisualThemes",
        meta: {
          title: "Temas (ID Visual)",
          icon: Icons.IDVISUAL,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.theme"],
        },
        component: () => import("@/views/ecommerce/Theme.vue"),
      },
      {
        path: "featured",
        name: "EcommerceFeatured",
        meta: {
          title: "Destaques",
          icon: Icons.STAR,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.features"],
        },
        component: () => import("@/views/featured/Featured.vue"),
      },
      {
        path: "publicity",
        name: "EcommercePublicity",
        meta: {
          title: "Publicidade",
          icon: Icons.SELECTALL,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.advertising"],
        },
        component: () =>
          import("@/views/ecommerce/advertising/Advertising.vue"),
      },
      {
        path: "termos-uso",
        name: "EcommerceTermsUse",
        meta: {
          title: "Termos de uso",
          icon: Icons.FILEDOCUMENT,
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["configuration.ecommerce.terms"],
        },
        component: () =>
          import("@/views/ecommerce/terms-use/TermsAndConditions.vue"),
        children: [
          {
            path: ":id",
            name: "EcommerceVisualizeTermUse",
            meta: {
              title: "Cadastrar Termos de Uso",
              local: ["navBar"],
              permissions: ["admin", "staff"],
              staffPermissions: ["configuration.ecommerce.terms"],
            },
            component: () =>
              import(
                "@/views/ecommerce/terms-use/TermsAndConditionAddEdit.vue"
              ),
          },
        ],
      },
    ],
  },
];
