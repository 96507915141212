import { cloneDeep, get } from "lodash";
import { compareObjects } from "@/utils/utils";
export default class BaseFilter {
  baseFilter = null;
  filter = null;
  temporary = null;
  pagination = {};
  touch = false;
  query = null;

  constructor(baseFilter = {}, pagination = {}) {
    const { page = 1, itemsPerPage = 25 } = pagination;
    this.pagination = {
      page,
      itemsPerPage,
    };
    this.baseFilter = Object.freeze(cloneDeep(baseFilter));
    this.resetFilter();
  }
  get comparator() {
    return compareObjects({ ...this.filter }, { ...this.temporary });
  }
  resetFilter() {
    this.filter = cloneDeep({ ...this.baseFilter });
    this.temporary = cloneDeep({ ...this.baseFilter });
    this.pagination = {
      page: 1,
      itemsPerPage: 50,
    };
    this.touch = false;
  }
  saveFilter() {
    if (this.comparator.hasChanges) {
      this.pagination.page = 1;
      this.touch = true;
    }
    this.filter = {
      ...this.filter,
      ...cloneDeep(this.temporary),
    };
  }
  setPagination(pagination = {}) {
    Object.assign(this.pagination, pagination);
  }
  getFilterCopy() {
    const filterCopy = cloneDeep(this.filter);
    if (filterCopy?.state === "all") filterCopy.state = "";
    return filterCopy;
  }
  get(key, defaultValue = null) {
    return get(this.filter, key, defaultValue);
  }
}
