import Vue from "vue";
import icons from "@/constants/Icons";

Vue.config.productionTip = false;

Vue.prototype.$icons = icons;

Vue.prototype.$redirect = function async(params) {
  const currentRoute = this.$route;
  const { route: nextRoute } = this.$router.resolve(params);

  if (currentRoute.fullPath === nextRoute.fullPath) {
    return Promise.resolve(this);
  }

  try {
    return this.$router.push(nextRoute);
  } catch (e) {
    console.warn("[navegação]: falhou ao acessar rota: %s", nextRoute.fullPath);
  }
};

String.prototype["convertToNumber"] = function (round = null) {
  let ret = this.replace(/[^-0-9,.]+/g, "");
  let decimalChar,
    thousandChar,
    commaIndex = ret.indexOf(","),
    dotIndex = ret.indexOf(".");

  if (commaIndex !== -1) {
    if (dotIndex !== -1 && dotIndex > commaIndex) {
      decimalChar = ".";
      thousandChar = ",";
    } else {
      decimalChar = ",";
      thousandChar = ".";
    }
    ret = ret.split(thousandChar).join("");
    if (decimalChar === ",") ret = ret.split(",").join(".");
  }
  if (round) ret = Number(ret).toFixed(round);
  return Number(ret);
};

Number.prototype["convertToNumber"] = function (round = null) {
  return round ? Number(this.toFixed(round)) : this;
};
Number.prototype["toBRNumber"] = function (min = 0, max = 2) {
  return this.toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

Date.prototype["toBRDate"] = function () {
  return this.toLocaleDateString("pt-br");
};
Date.prototype["toBRTime"] = function () {
  return this.toLocaleTimeString("pt-br");
};
Date.prototype["toBRDateHour"] = function () {
  return this.toLocaleString("pt-br");
};

Window["invertFormatMoney"] = (num) =>
  num.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
