import store from "../store";
import router from "../router";
import axios from "axios";
import {
  CRM_ROUTES,
  INVERTER_TYPES,
  PRODUCT_TYPES,
  SYSTEM_STATUS_OBJECT,
  TRANSLATED_ORIGIN_ENERGY_COMPANY,
  TYPES_LINK,
} from "@/utils/constants";
import { User } from "@/request";
import { stringToDate, stringToTime } from "@/utils/DateUtils";
import { isEqualWith } from "lodash";
import {
  PaymentConditionModifierTypeEnum,
  PaymentConditionTypeEnum,
  PaymentShowConditionsRuleEnum,
} from "@/constants/Payment";
import FindAddressService from "@/app/services/FindAddressService";

async function invalidSessionKey(e) {
  const unexpectedError = {
    title: "Erro Inesperado",
    description: "Tente novamente mais tarde ou entre em contato",
    buttons: Array(1),
    type: "unexpectedError",
  };

  let title = "";
  let message = "";
  let onCancel = undefined;

  if (e.error && e.error.title && e.error.description) {
    title = e.error.title;
    message = e.error.description;
    onCancel = [
      "maintenance",
      "maintenanceStaff",
      "invalidSessionKey",
      "accessDenied",
      "integratorUserInactive",
    ].includes(e.error.type)
      ? () => returnLogin()
      : null;
  } else if (!e.error) {
    title = unexpectedError.title;
    message = unexpectedError.description;
  }

  await store.dispatch("NotifyDialog/openNotifyError", {
    title: title,
    message: message,
    onCancel: onCancel,
  });
}

async function setPermissions() {
  let user = store.getters["userData/getUserData"];
  let integratorPermissions = {
    howToDisplayPricesInClosedKits: "never",
    howToDisplayProductsPricesInMountKit: "never",
    howToDisplayProductsPricesInKitExpress: "never",
    howToDisplayKitPriceInMountKit: "onRequest",
    howToDisplayKitPriceInKitExpress: "onRequest",
    howToDisplayPricesInSeparateSale: "never",
    howToDisplayPricesExtraItems: "never",
    howToDisplayFreightValue: "never",
    editableInvoce: await checkPermissions(
      ["editableInvoce.true"],
      ["editableInvoce.true"],
      user
    ),
    editableCompanyInvoce: await checkPermissions(
      ["companyEditableInvoce.true"],
      ["companyEditableInvoce.true"],
      user
    ),
    editableInvoceTotal: await checkPermissions(
      ["editableInvoce.true.total"],
      ["editableInvoce.true.total"],
      user
    ),
    editableCompanyInvoceTotal: await checkPermissions(
      ["companyEditableInvoce.true.total"],
      ["companyEditableInvoce.true.total"],
      user
    ),
    editableInvoceService: await checkPermissions(
      ["editableInvoce.true.service"],
      ["editableInvoce.true.service"],
      user
    ),
    editableCompanyInvoceService: await checkPermissions(
      ["companyEditableInvoce.true.service"],
      ["companyEditableInvoce.true.service"],
      user
    ),
    showBudgetButton: await checkPermissions(
      [],
      ["requestButton.budget"],
      user
    ),
    showRequestButton: await checkPermissions(
      [],
      ["requestButton.order"],
      user
    ),
    showSellerToIntegrator: await checkPermissions(
      [],
      ["seeResponsible"],
      user
    ),
    hasDefaultDiscount: await checkPermissions([], ["defaultDiscount"], user),
  };
  let options = {
    howToDisplayPricesInClosedKits: {
      onSelection: ["closedKits.totalPrice.onSelection"],
      onRequest: ["closedKits.totalPrice.onRequest"],
    },
    howToDisplayProductsPricesInMountKit: {
      onSelection: ["customKits.singlePrice.onSelection"],
      onConfirmation: ["customKits.singlePrice.onConfirmation"],
      onRequest: ["customKits.singlePrice.onRequest"],
    },
    howToDisplayKitPriceInMountKit: {
      onConfirmation: ["customKits.totalPrice.onConfirmation"],
    },
    howToDisplayProductsPricesInKitExpress: {
      onSelection: ["kitExpress.singlePrice.onSelection"],
      onConfirmation: ["kitExpress.singlePrice.onConfirmation"],
      onRequest: ["kitExpress.singlePrice.onRequest"],
    },
    howToDisplayKitPriceInKitExpress: {
      onSelection: ["kitExpress.totalPrice.onSelection"],
    },
    howToDisplayPricesInSeparateSale: {
      onSelection: ["separateSale.singlePrice.onSelection"],
      onRequest: ["separateSale.singlePrice.onRequest"],
    },
    howToDisplayPricesExtraItems: {
      showPrices: ["extraItem.showPrices"],
      dontShowPrices: ["extraItem.dontShowPrices"],
    },
    howToDisplayFreightValue: {
      onSelection: ["freightPrice.onSelection"],
      onRequest: ["freightPrice.onRequest"],
    },
  };
  if (user.type === "admin") {
    Object.assign(integratorPermissions, {
      howToDisplayPricesInClosedKits: "onSelection",
      howToDisplayProductsPricesInMountKit: "onSelection",
      howToDisplayKitPriceInMountKit: "onConfirmation",
      howToDisplayProductsPricesInKitExpress: "onSelection",
      howToDisplayKitPriceInKitExpress: "onSelection",
      howToDisplayPricesInSeparateSale: "onSelection",
      howToDisplayFreightValue: "onSelection",
    });
  } else {
    for (let key of Object.keys(options)) {
      for (let subKey of Object.keys(options[key])) {
        if (
          await checkPermissions(
            options[key][subKey],
            options[key][subKey],
            user
          )
        ) {
          integratorPermissions[key] = subKey;
        }
      }
    }
  }
  if (user.type === "staff" || user.type === "admin") {
    Object.assign(integratorPermissions, {
      howToDisplayPricesExtraItems: "showPrices",
    });
  }
  store.commit("INTEGRATOR_PERMISSIONS", integratorPermissions);
}

function formatNumberToBrMoney(num) {
  //TODO: [carlos] onde essa função for chamada, substituir pela função padrão equivalente no main.js
  if (typeof num != "number") return;
  return Window.invertFormatMoney(num);
}

export function validateEmail(email) {
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
}

export function validateCNPJ(val) {
  if (!val) return false;

  const cnpj = val.replace(/[^0-9]+/g, "");

  if (cnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  const tamanho = cnpj.length - 2;
  const digitos = cnpj.substring(tamanho);
  const digitos1 = parseInt(digitos.charAt(0));
  const digitos2 = parseInt(digitos.charAt(1));

  const cal = (tamanho) => {
    const numeros = cnpj.substring(0, tamanho);
    let pos = tamanho - 7;
    let soma = 0;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    const resultado = 11 - (soma % 11);

    return resultado > 9 ? 0 : resultado;
  };

  return cal(tamanho) === digitos1 && cal(tamanho + 1) === digitos2;
}

export function validateCPF(val) {
  if (!val) return false;

  let sum;
  let remain;
  const cpf = val.replace(/[^0-9]+/g, "");

  sum = 0;
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  remain = (sum * 10) % 11;

  if (remain === 10 || remain === 11) remain = 0;
  if (remain !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  remain = (sum * 10) % 11;

  if (remain === 10 || remain === 11) remain = 0;
  return remain === parseInt(cpf.substring(10, 11));
}

export function validateHour(hour) {
  const validate = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    isValid = validate.test(hour);
  if (isValid) return true;
  else return "Hora Inválida";
}

export function getCep(cep, force = false) {
  return FindAddressService.search(cep, force);
}

export function getCities(uf) {
  return axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
  );
}

export async function getDownloadFile(data, fileName, retStatus = 200) {
  let dataBlob = new Blob([data]);
  if (retStatus !== 200) {
    const readUploadedFileAsText = (inputFile) => {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
          temporaryFileReader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsText(inputFile);
      });
    };

    return JSON.parse(await readUploadedFileAsText(dataBlob));
  }

  const url = window.URL.createObjectURL(dataBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

const required = (value) => {
  let newValue =
    typeof value === "string"
      ? value.trim()
      : Array.isArray(value)
      ? value.length
      : value;
  return !!newValue || newValue === false || "Campo obrigatório";
};

const maxLength =
  (length = 30) =>
  (value) => {
    const newValue =
      typeof value === "string" ? value.trim() : String(value).trim();
    return (
      newValue.length <= length ||
      `O tamanho máximo para esse campo é de ${length} caracteres`
    );
  };

const phoneSize = (value) => {
  let newValue = typeof value === "string" ? value.trim() : value;
  return (
    !newValue || newValue.length >= 14 || "O número de telefone está incompleto"
  );
};

const validDate = (value) => {
  const date = stringToDate(value);

  return !value || (date instanceof Date && !isNaN(date)) || "Data inválida";
};
const validTime = (value) => {
  const time = stringToTime(value);

  if (value.length < 5) return "Hora inválida";

  return !value || (time instanceof Date && !isNaN(time)) || "Hora inválida";
};

const requiredPassword = (value) => {
  let strong = `A senha precisa conter ao menos:`,
    total = (value || "").length,
    lowerCase = (value.match(/[a-z]/g) || []).length,
    upperCase = (value.match(/[A-Z]/g) || []).length,
    numbers = (value.match(/[0-9]/g) || []).length,
    specialChars = total - lowerCase - upperCase - numbers,
    useComma = "";
  let typeCheck = (test, message) => {
    if (test) {
      strong = strong + useComma + message;
      useComma = ",";
    }
  };
  typeCheck(total < 8, " 8 caracteres");
  typeCheck(lowerCase === 0, " 1 letra MINÚSCULA");
  typeCheck(upperCase === 0, " 1 letra MAIÚSCULA");
  typeCheck(numbers === 0, " 1 número");
  typeCheck(specialChars === 0, " 1 símbolo");
  if (
    numbers > 0 &&
    lowerCase > 0 &&
    upperCase > 0 &&
    specialChars > 0 &&
    total >= 8
  )
    strong = true;
  return strong;
};

const requiredPasswordOptional = (value) => {
  let strong = `A senha precisa conter ao menos:`,
    total = (value || "").length,
    lowerCase = (value.match(/[a-z]/g) || []).length,
    upperCase = (value.match(/[A-Z]/g) || []).length,
    numbers = (value.match(/[0-9]/g) || []).length,
    specialChars = total - lowerCase - upperCase - numbers,
    useComma = "";
  let typeCheck = (test, message) => {
    if (test) {
      strong = strong + useComma + message;
      useComma = ",";
    }
  };
  typeCheck(total < 8, " 8 caracteres");
  typeCheck(lowerCase === 0, " 1 letra MINÚSCULA");
  typeCheck(upperCase === 0, " 1 letra MAIÚSCULA");
  typeCheck(numbers === 0, " 1 número");
  typeCheck(specialChars === 0, " 1 símbolo");
  if (total === 0) strong = true;
  if (
    numbers > 0 &&
    lowerCase > 0 &&
    upperCase > 0 &&
    specialChars > 0 &&
    total >= 8
  )
    strong = true;
  return strong;
};

const compareValues = (value1, value2, msg) => {
  return value1 === value2 || msg;
};

export async function returnLogin() {
  router.push({ name: "Login" });
}

export function returnInitialPage(type = "") {
  return router.push(getInitialPage(type));
}

export function getInitialPage(type = "") {
  switch (type) {
    case "piedAdmin":
      return { name: "Distributors" };
    case "admin":
    case "staff":
      return { name: "Dashboard" };
    case "integrator":
      return { name: "DashboardIntegrator" };
    default:
      return { name: "Login" };
  }
}

export function calculateFinalRequestValue(totalValue, valueModifier) {
  return totalValue + getRequestFinalModifiedValue(totalValue, valueModifier);
}

export function getRequestFinalModifiedValue(totalValue, valueModifier) {
  let finalValue = totalValue;
  if (valueModifier?.increase?.amount && valueModifier.increase.type) {
    finalValue += getRequestIncreaseOrDecreaseValue(
      totalValue,
      valueModifier.increase,
      "increase"
    );
  }
  if (valueModifier?.decrease?.amount && valueModifier.decrease.type) {
    finalValue += getRequestIncreaseOrDecreaseValue(
      finalValue,
      valueModifier.decrease,
      "decrease"
    );
  }
  return finalValue - totalValue;
}

export function getRequestIncreaseOrDecreaseValue(
  totalValue,
  valueModifier,
  type
) {
  const modifier = type === "increase" ? 1 : -1;
  if (valueModifier?.type === "fixed")
    return modifier * valueModifier.amount || 0;
  else
    return modifier * ((valueModifier?.amount || 0) / 100) * (totalValue || 0);
}

export function numberStringComputed(numbers) {
  let ret = {};
  for (let number of numbers) {
    let splitNumber = number.split(".");
    ret[splitNumber.slice(-1) + "Computed"] =
      numberStringComputedBase(splitNumber);
  }
  return ret;
}

export function numberStringComputedBase(variable) {
  return {
    get() {
      let obj = variable
        .slice(0, -1)
        .reduce((a, b) => (a[b] != undefined ? a[b] : a), this);
      let string = obj[variable.slice(-1)];
      if (typeof string === "number" || typeof string === "string")
        string = String(string).replace(".", ",");
      else string = string || "";
      return string;
    },
    set(newValue) {
      let obj = variable
        .slice(0, -1)
        .reduce((a, b) => (a[b] != undefined ? a[b] : a), this);
      obj[variable.slice(-1)] =
        newValue !== "" ? Number(newValue.replace(",", ".")) : null;
    },
  };
}

export function checkPermissions(
  staffReqPerm,
  integratorReqPerm = [],
  user = null,
  onePermissionNeeded = false
) {
  if (!user) user = store.getters["userData/getUserData"];

  let array = user.type === "staff" ? staffReqPerm : integratorReqPerm;

  if (user.type === "integrator" && integratorReqPerm.length === 0)
    return false;
  let check = true;

  if (user.type !== "admin") {
    let userPermissions = user.role?.permissions || [];
    if (userPermissions.length === 0 || user.type !== user.role.type)
      check = false;
    for (let permission of array) {
      if (onePermissionNeeded) {
        check = userPermissions.includes(permission);
        if (check) break;
      } else {
        check = check && userPermissions.indexOf(permission) !== -1;
      }
    }
  }

  return check;
}

export function getResultSizingData(sizing) {
  const sizingArray = getResultSizingDataArray(sizing);
  return sizingArray.find((s) => s.type === sizing.selectedAnalyze);
}

export function getResultSizingDataArray(sizing) {
  const analyze = sizing.analyze,
    fatorCorrecao = sizing.fatorCorrecao;
  if (sizing.group === "B") {
    return [
      {
        type: "100",
        active: !!analyze[100].geracao_media_mensal,
        power:
          (analyze["100"].potencia_necessaria_convencional_100
            ? analyze["100"].potencia_necessaria_convencional_100
            : analyze["100"].potencia_necessaria) / fatorCorrecao,
        necessaryArea: Number(analyze["100"].area_necessaria / fatorCorrecao),
        attendedConsumptionPercentage: 100,
        monthlyAverageGeneration: analyze["100"].geracao_media_mensal,
      },
      {
        type: "area_disponivel",
        active: !!(
          sizing.disponibleArea &&
          analyze["area_disponivel"].geracao_media_mensal_limitada_area
        ),
        power: analyze["area_disponivel"].potencia_limitada_area,
        necessaryArea: Number(sizing.disponibleArea),
        attendedConsumptionPercentage:
          analyze["area_disponivel"].porcentagem_consumo_atencida *
          100 *
          fatorCorrecao,
        monthlyAverageGeneration:
          analyze["area_disponivel"].geracao_media_mensal_limitada_area *
          fatorCorrecao,
      },
    ];
  } else if (sizing.group === "A") {
    return [
      {
        type: "caso_1",
        active: true,
        power: analyze["caso_1"].potencia_necessaria / fatorCorrecao,
        necessaryArea: analyze["caso_1"].area_necessaria / fatorCorrecao,
        attendedConsumptionPercentage: 100,
        monthlyAverageGeneration: analyze["caso_1"].geracao_media_mensal,
      },
      {
        type: "caso_2",
        active: !!(sizing.tariffType === "Azul"
          ? analyze["caso_2"].porcentagem_consumo_atendida * 100 < 100 &&
            sizing.demanContratadaPonta !== ""
          : analyze["caso_2"].porcentagem_consumo_atendida * 100 < 100 &&
            sizing.demanContratada !== ""),
        power: analyze["caso_2"].potencia_necessaria * fatorCorrecao,
        necessaryArea: Number(sizing.disponibleArea),
        attendedConsumptionPercentage:
          analyze["caso_2"].porcentagem_consumo_atendida * 100 * fatorCorrecao,
        monthlyAverageGeneration:
          analyze["caso_2"].geracao_media_mensal * fatorCorrecao,
      },
      {
        type: "caso_3",
        active: !!(
          analyze["caso_3"].porcentagem_consumo_atendida &&
          analyze["caso_3"].porcentagem_consumo_atendida * 100 < 100 &&
          sizing.installedPower !== "" &&
          sizing.installedPower !== 0
        ),
        power: analyze["caso_3"].potencia_necessaria * fatorCorrecao,
        necessaryArea: Number(analyze["caso_3"].area_necessaria),
        attendedConsumptionPercentage:
          analyze["caso_3"].porcentagem_consumo_atendida * 100 * fatorCorrecao,
        monthlyAverageGeneration:
          analyze["caso_3"].geracao_media_mensal * fatorCorrecao,
      },
      {
        type: "area_disponivel",
        active: !!(
          analyze["area_disponivel"].porcentagem_consumo_atendida * 100 < 100 &&
          analyze["area_disponivel"].potencia_limitada_area !== 0
        ),
        power: analyze["area_disponivel"].potencia_limitada_area,
        necessaryArea: Number(sizing.disponibleArea),
        attendedConsumptionPercentage:
          analyze["area_disponivel"].porcentagem_consumo_atendida *
          100 *
          fatorCorrecao,
        monthlyAverageGeneration:
          analyze["area_disponivel"].geracao_media_mensal * fatorCorrecao,
      },
    ];
  } else if (sizing.group === "Fast") {
    return [
      {
        type: "100",
        active: true,
        power:
          (analyze["100"].potencia_necessaria_convencional_100
            ? analyze["100"].potencia_necessaria_convencional_100
            : analyze["100"].potencia_necessaria) / fatorCorrecao,
        necessaryArea: analyze["100"].area_necessaria / fatorCorrecao,
        attendedConsumptionPercentage: 100,
        monthlyAverageGeneration: analyze["100"].geracao_media_mensal,
      },
    ];
  }
}

export function getFinancedPaybackData(financingType, simulation) {
  let iof = null,
    table = simulation.price.IOFParceled.table;

  if (financingType === "priceCash") {
    iof = simulation.price.IOFInCash.IOFInCash;
    table = simulation.price.IOFInCash.table;
  } else if (financingType === "sacCash") {
    iof = simulation.sac.IOFInCash.IOFInCash;
    table = simulation.sac.IOFInCash.table;
  } else if (financingType === "sacFinanced") {
    table = simulation.sac.IOFParceled.table;
  }

  return { iof, table };
}

export function getPaybackData(resultSizing) {
  let tipo = "B-Convencional",
    dataDeSolicitacaoAcesso = resultSizing.dataDeSolicitacaoAcesso,
    fatorDeSimultaneidade = resultSizing.fatorDeSimultaneidade,
    complementosTarifarios = resultSizing.complementosTarifarios,
    modalidadeDaGeracao = resultSizing.modalidadeDaGeracao,
    complementoModalidadeDaGeracao =
      resultSizing.complementoModalidadeDaGeracao,
    potencia = resultSizing.potencia,
    areaDisponivel = resultSizing.necessaryArea,
    faresConvencionalOrResidencial = 0,
    consumptionFastOrB = 0,
    tipoLigacao = resultSizing.typeLink,
    tensaoAbastecimento = resultSizing.supplyTension,
    disjuntorPadraoEntrada = resultSizing.disjuntorPadraoEntrada,
    potenciaInstalada = resultSizing.installedPower || 0,
    enConsAFP = resultSizing.consumoMedioForaPonta || 0,
    enConsAP = resultSizing.consumoMedioPonta || 0,
    enConsDemP = resultSizing.demanContratadaPonta || 0,
    enConsDemFP = resultSizing.demanContratada || 0,
    enConsBBrancaFP = resultSizing.consumoForaPonta || 0,
    enConsBBrancaI = resultSizing.consumoIntermediario || 0,
    enConsBBrancaP = resultSizing.consumoPonta || 0,
    tarifaIntermediario =
      resultSizing.fares.intermediaria || resultSizing.fares.intermediario || 0,
    tarifaPonta = resultSizing.fares.ponta || 0,
    tarifaForaPonta = resultSizing.fares.foraPonta || 0,
    tarifaTEPonta = resultSizing.fares.TEPonta || 0,
    tarifaTUSDPonta = resultSizing.fares.TUSDPonta || 0,
    tarifaTEForaPonta = resultSizing.fares.TEForaPonta || 0,
    tarifaTUSDForaPonta = resultSizing.fares.TUSDForaPonta || 0,
    tarifaDemandaForaPonta = resultSizing.fares.demanForaPonta || 0,
    tarifaDemandaPonta = resultSizing.fares.demanPonta || 0,
    enConAIrr = resultSizing.consumoMedioIrrigacao || 0,
    tarifaTEIrrigacao = resultSizing.fares.TEIrrigação || 0,
    tarifaTUSDIrrigacao = resultSizing.fares.TUSDIrrigação || 0,
    enConBIrr = resultSizing.consumoMedioMensalIrrigacao || 0,
    tarifaIrrigacao = resultSizing.fares.Irrigação || 0;

  if (resultSizing.group === "A") tipo = "A";
  if (resultSizing.group === "B") {
    if (resultSizing.tariffType === "convencional") {
      faresConvencionalOrResidencial = resultSizing.fares.convencional;
      consumptionFastOrB = resultSizing.consumoMedioMensal;
    } else {
      tipo = "B-Branca";
    }
  }

  if (resultSizing.group === "Fast") {
    faresConvencionalOrResidencial = resultSizing.fares.Residencial;
    consumptionFastOrB = resultSizing.analyze.consumoEstimado;
  }
  let tarifaConvencional = faresConvencionalOrResidencial,
    enCons = consumptionFastOrB;
  return {
    tipo,
    tipoLigacao,
    tensaoAbastecimento,
    disjuntorPadraoEntrada,
    potenciaInstalada,
    areaDisponivel,
    enCons,
    enConsAFP,
    enConsAP,
    enConsDemP,
    enConsDemFP,
    enConsBBrancaFP,
    enConsBBrancaI,
    enConsBBrancaP,
    tarifaConvencional,
    tarifaIntermediario,
    tarifaPonta,
    tarifaForaPonta,
    tarifaTEForaPonta,
    tarifaTUSDForaPonta,
    tarifaTEPonta,
    tarifaTUSDPonta,
    tarifaDemandaForaPonta,
    tarifaDemandaPonta,
    enConAIrr,
    tarifaTEIrrigacao,
    tarifaTUSDIrrigacao,
    enConBIrr,
    tarifaIrrigacao,
    dataDeSolicitacaoAcesso,
    fatorDeSimultaneidade,
    complementosTarifarios,
    modalidadeDaGeracao,
    complementoModalidadeDaGeracao,
    potencia,
  };
}

export function formatFinancingValues(financingSimulation) {
  let tax = financingSimulation?.simulation?.price?.IOFInCash?.financing?.tax
      ? String(
          financingSimulation.simulation.price.IOFInCash.financing.tax.toLocaleString(
            "pt-BR",
            { minimumFractionDigits: 0, maximumFractionDigits: 2 }
          )
        ).concat(" ", "% a.m.")
      : "-",
    priceCashIOFCash = financingSimulation?.simulation?.price?.IOFInCash
      ?.IOFInCash
      ? formatNumberToBrMoney(
          financingSimulation.simulation.price.IOFInCash.IOFInCash
        )
      : "-",
    priceCashValueFinanced = financingSimulation?.simulation?.price?.IOFInCash
      ?.financing?.financingValue
      ? formatNumberToBrMoney(
          financingSimulation.simulation.price.IOFInCash.financing
            .financingValue
        )
      : "-",
    priceFinancedValueFinanced = financingSimulation?.simulation?.price
      ?.IOFParceled?.financing
      ? formatNumberToBrMoney(
          financingSimulation.simulation.price.IOFParceled.financing
        )
      : "-",
    priceCashParcel = financingSimulation?.simulation?.price?.IOFInCash?.parcel
      ? formatNumberToBrMoney(
          financingSimulation.simulation.price.IOFInCash.parcel
        )
      : "-",
    priceFinancedParcel = financingSimulation?.simulation?.price?.IOFParceled
      ?.parcel
      ? formatNumberToBrMoney(
          financingSimulation.simulation.price.IOFParceled.parcel
        )
      : "-",
    sacCashIOFInCash = financingSimulation?.simulation?.sac?.IOFInCash
      ?.IOFInCash
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFInCash.IOFInCash
        )
      : "-",
    sacCashValueFinanced = financingSimulation?.simulation?.sac?.IOFInCash
      ?.financing?.financingValue
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFInCash.financing.financingValue
        )
      : "-",
    sacFinancedValueFinanced = financingSimulation?.simulation?.sac?.IOFParceled
      ?.financing
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFParceled.financing
        )
      : "-",
    sacCashFirstParcel = financingSimulation?.simulation?.sac?.IOFInCash
      ?.firstParcelSAC
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFInCash.firstParcelSAC
        )
      : "-",
    sacFinancedFirstParcel = financingSimulation?.simulation?.sac?.IOFParceled
      ?.firstParcelSAC
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFParceled.firstParcelSAC
        )
      : "-",
    sacCashLastParcel = financingSimulation?.simulation?.sac?.IOFInCash
      ?.lastParcelSAC
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFInCash.lastParcelSAC
        )
      : "-",
    sacFinancedLastParcel = financingSimulation?.simulation?.sac?.IOFParceled
      ?.lastParcelSAC
      ? formatNumberToBrMoney(
          financingSimulation.simulation.sac.IOFParceled.lastParcelSAC
        )
      : "-";

  return {
    tax,
    priceCashIOFCash,
    priceCashValueFinanced,
    priceFinancedValueFinanced,
    priceCashParcel,
    priceFinancedParcel,
    sacCashIOFInCash,
    sacCashValueFinanced,
    sacFinancedValueFinanced,
    sacCashFirstParcel,
    sacFinancedFirstParcel,
    sacCashLastParcel,
    sacFinancedLastParcel,
  };
}

export function formatWhatsAppContact(cellphoneString) {
  let result = "";
  const formattedCellphone = String(cellphoneString).replace(/[^0-9]+/g, "");

  if (formattedCellphone.length === 11 || formattedCellphone.length === 10) {
    result = `http://wa.me/55${formattedCellphone}`;
  }

  return result;
}

export function getSystemStatusNegotiationOrSold(type) {
  if (!type) return [];

  return Object.keys(SYSTEM_STATUS_OBJECT)
    .filter((key) => {
      if (type === "negotiation") {
        if (
          (Object.keys(SYSTEM_STATUS_OBJECT).indexOf(key) >= 0 &&
            Object.keys(SYSTEM_STATUS_OBJECT).indexOf(key) <= 10) ||
          key === "lost"
        )
          return key;
      } else {
        if (
          Object.keys(SYSTEM_STATUS_OBJECT).indexOf(key) >= 10 &&
          Object.keys(SYSTEM_STATUS_OBJECT).indexOf(key) < 20
        )
          return key;
      }
    })
    .map((item) => {
      return { status: item, name: SYSTEM_STATUS_OBJECT[item] };
    });
}

export function validationNumberComma($event) {
  if (!$event) {
    return "";
  }
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  let stopComma =
    (!$event.target.value ||
      ($event.target.value.match(/,/g) || []).length > 0) &&
    keyCode === 44;
  if ((keyCode !== 44 && keyCode < 48) || keyCode > 57 || stopComma) {
    $event.preventDefault();
    return "Digite apenas números ou vírgula.";
  } else return "";
}

export function validationCommaDot($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if (
    (keyCode !== 44 && keyCode !== 47 && keyCode !== 32 && keyCode < 48) ||
    (keyCode > 57 && keyCode !== 59)
  ) {
    $event.preventDefault();
    return "Digite apenas números, vírgula ou ponto e vírgula.";
  } else return "";
}

export function validationNumber($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if (keyCode < 48 || keyCode > 57) {
    $event.preventDefault();
    return "Carácter inválido.";
  }
}

export function validationNegativeNumber($event) {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  let stopNegative =
    ($event.target.value.match(/-/g) || []).length > 0 && keyCode === 45;

  if ((keyCode !== 45 && keyCode < 48) || keyCode > 57 || stopNegative) {
    $event.preventDefault();
    return "Carácter inválido.";
  }

  return "";
}

export function getTypesLinkArray() {
  return TYPES_LINK.map((typeLink) => {
    return {
      name: typeLink.text,
      value: typeLink.valueNumber,
    };
  });
}

export function getTypesLinkStringTranslation() {
  let result = {};
  TYPES_LINK.forEach((typeLink) => {
    result[typeLink.valueString] = typeLink.text;
  });
  return result;
}

export function getTypesLinkNumberTranslation() {
  let result = {};
  TYPES_LINK.forEach((typeLink) => {
    result[typeLink.valueNumber] = typeLink.text;
  });
  return result;
}

export function getTypesLinkLabelValueString() {
  return TYPES_LINK.map((typeLink) => {
    return {
      label: typeLink.text,
      value: typeLink.valueString,
    };
  }).filter((typeLink) => typeLink.value !== "bifasico");
}

export function getInverterTypesLabelValue() {
  return Object.keys(INVERTER_TYPES).map((key) => {
    return {
      label: INVERTER_TYPES[key],
      value: key,
    };
  });
}

export function getProductTypesNameValue() {
  return Object.keys(PRODUCT_TYPES).map((key) => {
    return {
      name: PRODUCT_TYPES[key],
      value: key,
    };
  });
}

export function getRuntimeEnv(type) {
  return window.config?.[type] || process.env?.[type] || "";
}

export function getCompanyOriginsObject() {
  const result = { ...TRANSLATED_ORIGIN_ENERGY_COMPANY };
  delete result.indication;
  result.seller = "Vendedor";
  return result;
}

export function isSizingOrProposalCRMRoute(routeName = "") {
  return CRM_ROUTES.filter((item) => item.includes("CRM")).includes(
    routeName || router.history.current.name
  );
}

export function dashboardBaseMoneyConvert(val) {
  let baseVal = val?.convertToNumber() || 0;
  let append = "";
  if (baseVal > 1e3) {
    if (baseVal < 1e6) {
      baseVal = baseVal / 1e3;
      append = "mil";
    } else if (baseVal < 1e9) {
      baseVal = baseVal / 1e6;
      append = "Mi";
    } else if (baseVal < 1e12) {
      baseVal = baseVal / 1e9;
      append = "Bi";
    } else {
      baseVal = baseVal / 1e12;
      append = "Tri";
    }
  }
  return (
    baseVal.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: baseVal > 100 ? 0 : 3,
    }) +
    " " +
    append
  );
}

export async function checkIsUserAllowedEditRequest(budgetCreationDate) {
  if (!budgetCreationDate) return false;
  if (await checkPermissions(["budget.details.edit.all"], ["budget.edit.all"]))
    return true;

  const ret = await User.readBudgetSetting();
  if (ret.error) return false;
  if (!ret.data?.budgetSettings?.active) return true;

  const budgetDay = new Date(budgetCreationDate).getDate(),
    budgetMonth = new Date(budgetCreationDate).getMonth(),
    budgetYear = new Date(budgetCreationDate).getFullYear(),
    budgetExpiryDate = new Date(
      budgetYear,
      budgetMonth,
      budgetDay + ret.data.budgetSettings.expiry,
      23,
      59,
      59
    );

  return new Date() <= budgetExpiryDate;
}

export function setSelectedOptions(option, property, filterObj) {
  const lastOption = option[option.length - 1];
  filterObj[property] =
    lastOption === "" ? null : option.filter((opt) => opt !== "");
}

export const sortPurchaseItems = (a, b) => {
  if (a.type === b.type && a.type !== "complementaryEquipment")
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  else if (a.type === "module") return -1;
  else if (a.type === "inverter" && b.type !== "module") return -1;
  else if (a.type === "stringBox" && !["module", "inverter"].includes(b.type))
    return -1;
  else if (
    a.type === "complementaryEquipment" &&
    b.type === "complementaryEquipment"
  ) {
    if (a.category?.type === b.category?.type)
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    else if (
      a.category?.type === "structure" ||
      !b.category.type ||
      b.category.type === "others"
    )
      return -1;
  }
  return 1;
};

export function parsePhoneNumber(input) {
  return input.replace(/\D/g, "");
}

export function compareObjects(oldObj, newObj, ignoreUndefined = true) {
  const changes = [];

  for (const [field, _oldValue] of Object.entries(oldObj)) {
    if (["id", "_id"].includes(field)) continue;
    const val = newObj[field];
    const _newValue = !_.isNil(val)
      ? typeof val === "string"
        ? val.trim()
        : val
      : undefined;

    //TODO: use advanced function comparator isEqualWith
    if (
      (_newValue === undefined && ignoreUndefined) ||
      (_oldValue == _newValue &&
        ((_.isNil(_oldValue) && _.isNil(_newValue)) ||
          (!_.isNil(_oldValue) && !_.isNil(_newValue)))) ||
      isEqualWith(_oldValue, _newValue)
    ) {
      continue;
    }

    changes.push({
      field,
      from: _oldValue,
      to: typeof _newValue === "string" ? _newValue.trim() : _newValue,
    });
  }

  for (const [field, _newValue] of Object.entries(newObj)) {
    if (["id", "_id"].includes(field)) continue;

    const val = oldObj[field];
    const _oldValue = !_.isNil(val)
      ? typeof val === "string"
        ? val.trim()
        : val
      : undefined;

    if (_oldValue !== undefined || !_newValue) continue;

    changes.push({
      field,
      from: "",
      to: typeof _newValue === "string" ? _newValue.trim() : _newValue,
    });
  }

  return {
    changes,
    hasChanges: changes.length > 0,
  };
}

export const applyPaymentCondition = (originalValue, condition) => {
  const value = Number(originalValue);
  if (!condition) return value;
  switch (condition.conditionType) {
    case "increase":
      if (condition.modifierType === "percentage") {
        return Number((value * (1 + condition.value / 100)).toFixed(2));
      }
      return value + condition.value;
    case "decrease":
      if (condition.modifierType === "percentage") {
        return Number((value * (1 - condition.value / 100)).toFixed(2));
      }
      return value - condition.value;
    default:
      return value;
  }
};

export const returnPaymentConditionAsPercentage = (value, condition) => {
  if (condition.modifierType === PaymentConditionModifierTypeEnum.PERCENTAGE)
    return `${Math.abs(condition.value)} %`;

  const appliedValue = applyPaymentCondition(value, condition);
  if (appliedValue === value || isNaN(appliedValue)) return "00 %";

  return `${Math.abs(((appliedValue - value) / value) * 100).toFixed(2)} %`;
};

export const applyPaymentConditionByInstallment = (value, condition) => {
  const installments = [];
  const totalWithCondition = Number(applyPaymentCondition(value, condition));
  const valueByInstallment = Number(
    (totalWithCondition / condition.quantityOfInstallments).toFixed(2)
  );
  for (let i = 0; i < condition.quantityOfInstallments; i++) {
    installments.push({ installment: i + 1, value: valueByInstallment });
  }
  return {
    installments,
    valueByInstallment,
    totalWithCondition,
    quantityOfInstallments: condition.quantityOfInstallments,
  };
};

export const renderConditionElement = (
  originalValue,
  showConditionsRule,
  condition
) => {
  const value = Number(originalValue);
  const appliedCondition = applyPaymentConditionByInstallment(value, condition);
  switch (showConditionsRule) {
    case PaymentShowConditionsRuleEnum.YES_SHOW_ONLY_VALUE_UPDATED:
      return `<div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                <span style="display: inline-block;">${condition.name} </span>
                <span style="${
                  appliedCondition.totalWithCondition < value
                    ? "display: inline-block; color: green !important;"
                    : "display: inline-block;"
                }">
                  ${
                    condition.conditionType ===
                    PaymentConditionTypeEnum.INCREASE
                      ? " + "
                      : condition.conditionType ===
                        PaymentConditionTypeEnum.DECREASE
                      ? " - "
                      : ""
                  }
                  ${
                    condition.modifierType ===
                    PaymentConditionModifierTypeEnum.PERCENTAGE
                      ? Number(condition.value).toFixed(2) + "%"
                      : formatNumberToBrMoney(condition.value || 0)
                  }
                </span>
              </div>`;
    case PaymentShowConditionsRuleEnum.YES_SHOW_FINAL_VALUE_UPDATED:
      return `<div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                <span style="display: inline-block;">${condition.name} </span>
                <span style="${
                  appliedCondition.totalWithCondition < value
                    ? "display: inline-block; color: green !important;"
                    : "display: inline-block;"
                }">
                ${condition.quantityOfInstallments}x de ${formatNumberToBrMoney(
        appliedCondition.valueByInstallment
      )} - Total: ${formatNumberToBrMoney(
        appliedCondition.totalWithCondition
      )} </span>
              </div>`;
    case PaymentShowConditionsRuleEnum.NOT_SHOW_VALUES:
      return `<div>${condition.name}</div>`;
    default:
      return `<div>${condition.name}</div>`;
  }
};
export const prepareDuplicationRequest = (data = {}) => {
  const request = { ...data };
  delete request.coupon;
  delete request.discountRequisition;
  delete request.statusHistorical;
  delete request.pastVersions;

  request.isCopy = true;
  request.id = null;
  request.valueModifier = {
    increase: {
      amount: 0,
      type: "percentage",
    },
    decrease: {
      amount: 0,
      type: "percentage",
    },
  };
  return request;
};

export const stringMask = (value, mask) => {
  let [posix, masked, total] = [0, "", mask.length];
  for (let i = 0; i <= total - 1; i++) {
    if (mask[i] !== "#") {
      masked += mask[i];
      continue;
    }
    if (!value[posix]) continue;
    masked += value[posix++];
  }
  return masked;
};

export {
  invalidSessionKey,
  setPermissions,
  compareValues,
  required,
  maxLength,
  phoneSize,
  validDate,
  validTime,
  requiredPassword,
  requiredPasswordOptional,
  formatNumberToBrMoney,
};
