const kWp = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function toCurrency(value, maximumFractionDigits = 2) {
  let numberVal = value;

  if (typeof value === "string") {
    numberVal = value.convertToNumber();
  } else if (typeof numberVal !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits:
      maximumFractionDigits >= 0 ? maximumFractionDigits : 2,
  });

  return formatter.format(numberVal);
}

export const toKWP = (value) => kWp.format(value) + ` kWp`;
