import axios from "axios";
import store from "@/store";
import router from "@/router";
import {
  getDownloadFile,
  setPermissions,
  invalidSessionKey,
  getRuntimeEnv,
  returnLogin,
  returnInitialPage,
  checkPermissions,
} from "@/utils/utils";
import { readPopUps } from "@/views/settings/advertising/PopUps/utils.js";
import { User, Open } from "@/request";
import { USER_AUTH } from "@/localStorage/storageKeys";
import { Storage } from "@/localStorage";
import refreshManager, { MANAGER_STATUS } from "@/utils/refreshManager";
import { CreateTransaction } from "@/plugins/sentry";

const baseURL = getRuntimeEnv("VUE_APP_baseURL");
const debugDistributor = getRuntimeEnv("VUE_APP_debugDistributor") || undefined;

const client = axios.create({
  baseURL,
});

const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  OK: 200,
};

const AUTH_ERROR_TYPE = {
  INVALID_TOKEN: "invalidToken",
  EXPIRED_TOKEN: "expiredToken",
  INVALID_API_PATH_ACCESS: "invalidApiPathAccess",
  INVALID_REFRESH_TOKEN: "invalidRefreshToken",
  INVALID_LEGACY_TOKEN: "legacyAuthenticationMethod",
};

export default client;

const getBaseHeader = async function (awaitRenewal = true) {
  if (awaitRenewal) await refreshManager.notPending();
  const user = (await Storage.getItem(USER_AUTH)) || {};

  const baseHeaderInfo = debugDistributor
    ? {
        distributor: debugDistributor,
      }
    : {};

  const { token = "undefined", accessKey = "" } = user;

  return {
    "access-key": token !== "undefined" ? accessKey : "",
    authorization: token,
    ...baseHeaderInfo,
  };
};

let _options = {};

const baseHTTP = async function (type, path, data = null, useHeader = true) {
  return baseHTTPAll(type, path, data, useHeader);
};

const baseDownloadHTTP = async function (
  type,
  fileName,
  checkStatus,
  path,
  data = null
) {
  _options = { responseType: "blob" };
  return baseHTTPAll(type, path, data, false, fileName, checkStatus);
};

const baseHTTPAll = async function (
  type,
  path,
  data = null,
  useHeader = false,
  fileName = null,
  checkStatus = null
) {
  let httpFnTypes = {
    put: client.put,
    get: client.get,
    delete: client.delete,
    post: client.post,
    patch: client.patch,
  };
  if (!httpFnTypes[type]) return { data: null, error: true };
  try {
    let config = [path];

    if (!["get", "delete"].includes(type)) config.push(data);

    const extraInfo = {
      headers: await getBaseHeader(path !== "/app/refreshSession"),
    };

    if (fileName) {
      if (useHeader && !extraInfo.headers) return { data: null, error: true };
      if (fileName) Object.assign(extraInfo, { responseType: "blob" });
    }

    if (
      ["get", "delete"].includes(type) &&
      data &&
      Object.entries(data).length
    ) {
      Object.assign(extraInfo, { params: data });
    }

    config.push(extraInfo);

    if (Object.entries(_options).length) Object.assign(config[1], _options);

    let ret = await httpFnTypes[type](...config);
    if (fileName)
      ret = {
        data: await getDownloadFile(
          ret.data,
          fileName,
          (checkStatus && ret.status) || STATUS_CODE.OK
        ),
      };
    if (ret && ret.data?.error) throw ret.data;
    return ret.data;
  } catch (e) {
    let ret = e;
    const errorType = e.response?.data?.error?.type || null;

    if (
      [
        AUTH_ERROR_TYPE.INVALID_REFRESH_TOKEN,
        AUTH_ERROR_TYPE.INVALID_TOKEN,
        AUTH_ERROR_TYPE.INVALID_LEGACY_TOKEN,
      ].includes(errorType) ||
      e.response?.status === STATUS_CODE.UNAUTHORIZED
    ) {
      return handleUnauthorized(
        e.response?.status === STATUS_CODE.UNAUTHORIZED
      );
    }

    if (
      e.response?.status === STATUS_CODE.FORBIDDEN &&
      errorType === AUTH_ERROR_TYPE.EXPIRED_TOKEN
    ) {
      await updateExpiredToken();
    }

    if (e.response?.data) {
      if (fileName)
        ret = await getDownloadFile(
          e.response.data,
          fileName,
          (checkStatus && e.response.status) ||
            STATUS_CODE.INTERNAL_SERVER_ERROR
        );
      else ret = e.response.data;
    }

    if (ret.forceReload || ret.reloadUserData) {
      const { token, accessKey } = ret.data;
      await updateUserData(token, accessKey);
    }

    if (
      ret.reloadUserData ||
      (e.response?.status === STATUS_CODE.FORBIDDEN &&
        errorType === AUTH_ERROR_TYPE.EXPIRED_TOKEN)
    ) {
      await reloadUserData();
      return baseHTTPAll(type, path, data, useHeader, fileName, checkStatus); // retry function
    } else if (ret.forceReload) {
      window.location.reload();
    } else if (!Object.values(AUTH_ERROR_TYPE).includes(errorType)) {
      await invalidSessionKey(ret);
    }
    return ret;
  } finally {
    _options = {};
  }
};

const handleUnauthorized = async (reload = true) => {
  await router.push({ name: "Login" });
  await Storage.setItem(USER_AUTH, {});
  if (reload) window.location.reload();
  return { data: null, error: true };
};

const updateExpiredToken = async (reload = false) => {
  if (refreshManager.getStatus() !== MANAGER_STATUS.PENDING) {
    refreshManager.setStatus(MANAGER_STATUS.PENDING);
    const user = (await Storage.getItem(USER_AUTH)) || {};
    const ret = await Open.refreshSession({ userId: user.id });
    refreshManager.setStatus(MANAGER_STATUS.RESOLVED);

    if (ret && !ret.error && ret.data) {
      await updateUserData(ret.data.token, ret.data.accessKey);
      if (reload) window.location.reload();
    } else {
      return handleUnauthorized();
    }
  } else {
    await refreshManager.notPending();
  }
};

const updateUserData = async (token, accessKey) => {
  const user = (await Storage.getItem(USER_AUTH)) || {};

  user.token = token;
  user.accessKey = accessKey;

  await Storage.setItem(USER_AUTH, user);

  return user;
};

const reloadUserData = async () => {
  const user = (await Storage.getItem(USER_AUTH)) || {};

  if (!user?.id) return returnLogin();

  const ret = await User.reloadUserData();

  if (!ret.error && ret.data && typeof ret.data === "object") {
    Object.assign(user, ret.data);
    await store.dispatch("userData/setUserData", user);

    readPopUps(); //TODO:verify this function
    setPermissions();

    let actionCheck = true;
    if (router.history.current.meta?.actions?.length) {
      actionCheck = router.history.current.meta.actions.reduce(
        (acc, currentAction) => {
          let cur =
            store.getters["userData/getUserData"].actions?.[currentAction];
          if (acc != null) return acc && cur;
          return cur;
        },
        null
      );
    }

    let permissionCheck = true,
      hasPermissionCheck =
        (user?.type === "integrator" &&
          router.history.current.meta?.integratorPermissions) ||
        (user?.type === "staff" &&
          router.history.current.meta?.staffPermissions) ||
        [];
    if (
      (ret.data?.distribuitor?.maintenance && ret.data.type === "integrator") ||
      (ret.data?.distribuitor?.maintenanceStaff?.actived &&
        ret.data?.distribuitor?.maintenanceStaff.rolesIds?.includes(
          ret.data.role?.id
        ))
    ) {
      return invalidSessionKey({
        error: {
          title: "Plataforma em manutenção",
          description:
            "Desculpe-nos pelo transtorno. Estamos atualizando a plataforma para melhorar ainda mais a sua experiência. Por favor, tente novamente mais tarde.",
          type: "maintenance",
        },
      });
    }
    if (hasPermissionCheck.length) {
      permissionCheck = await checkPermissions(
        router.history.current.meta?.staffPermissions,
        router.history.current.meta?.integratorPermissions,
        user,
        router.history.current.meta?.onePermissionNeeded
      );
    }
    if (!actionCheck || !permissionCheck) {
      returnInitialPage(user?.type);
    }
  } else {
    returnLogin();
  }
};

const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(Array.isArray(data) && !data.length) &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
};

const formData = (json) => {
  const formData = new FormData();
  buildFormData(formData, json);
  return formData;
};

const verifyBuildTimestamp = async (timestamp) => {
  const newBuildTimestamp = new Date(timestamp);
  const buildTimestamp = new Date(
    document?.documentElement?.dataset?.buildTimestampUtc?.toString()
  );
  if (newBuildTimestamp > buildTimestamp) {
    const index = await axios.get(``, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });
    const indexDate = new Date(
      index.data?.match(/(?<=data-build-timestamp-utc=")(.*?)(?=")/)?.[0] ||
        undefined
    );

    if (indexDate > buildTimestamp) {
      window.location.reload();
    } else {
      CreateTransaction("newVersionReload", {
        indexDate,
        buildTimestamp,
        newBuildTimestamp,
      }).save();
      console.error("wrong index.html");
    }
  }
};

export {
  baseURL,
  getBaseHeader,
  baseHTTP,
  baseDownloadHTTP,
  formData,
  reloadUserData,
  updateExpiredToken,
  verifyBuildTimestamp,
};
