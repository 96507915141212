import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "./plugins/bus";
import "./plugins/vuelidate";
import "./plugins/clipboard";
import "./plugins/sentry";
import "./plugins/v-input-facade";
import "./plugins/intercom";
import "./plugins/filters";
import "./plugins/prototype";

(async () => {
  await store.dispatch("userData/setDistDataAndTheme");

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    mounted() {
      document.addEventListener("click", (e) => {
        const id =
          e.target.id ||
          e.target.parentElement?.id ||
          e.target.parentElement?.parentElement?.id;
        if (!id) return;
        store.commit("tracking/CLICK", id);
      });
    },
  }).$mount("#app");
})();
