import { getRuntimeEnv } from "@/utils/utils";

const state = {
  name: "",
  email: "",
  user_id: "",
  user_hash: "",
};

const mutations = {
  ["NAME"](state, payload) {
    state.name = payload;
  },
  ["EMAIL"](state, payload) {
    state.email = payload;
  },
  ["USER_ID"](state, payload) {
    state.user_id = payload;
  },
  ["USER_HASH"](state, payload) {
    state.user_hash = payload;
  },
};

const actions = {
  ["START_INTERCOM"]({ commit, dispatch }, payload) {
    commit("NAME", payload.name);
    commit("EMAIL", payload.email);
    commit("USER_ID", payload.user_id);
    commit("USER_HASH", payload.user_hash);

    dispatch("INIT_INTERCOM");
  },
  ["RESET_INTERCOM"]({ commit }) {
    commit("NAME", "");
    commit("EMAIL", "");
    commit("USER_ID", "");
    window.Intercom("shutdown");
  },
  ["INIT_INTERCOM"]({ state }) {
    window.Intercom("boot", {
      app_id: getRuntimeEnv("VUE_APP_intercom_app_id"),
      email: state.email,
      name: state.name,
      user_id: state.user_id,
      user_hash: state.user_hash,
    });
  },
};

const getters = {
  name: (state) => state.name,
  email: (state) => state.email,
  user_id: (state) => state.user_id,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
