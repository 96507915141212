export const USER_AUTH = "USER_AUTH";
export const INFO_SIZING = "INFO_SIZING";
export const RESULT_SIZING = "RESULT_SIZING";
export const BUDGET_DETAIL = "BUDGET_DETAIL";
export const SELLER = "SELLER";
export const INFO_PROPOSAL = "INFO_PROPOSAL";
export const FINANCING_SIMULATION = "FINANCING_SIMULATION";
export const INFO_REQUEST = "INFO_REQUEST";
export const ADVERTISING_POPUP = "ADVERTISING_POPUP";
export const DISTRIBUTOR_THEME = "DISTRIBUTOR_THEME";
