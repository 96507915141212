<template>
  <v-menu
    v-model="isMenuOpen"
    transition="slide-y-transition"
    rounded="lg"
    z-index="10"
    nudge-bottom="4"
    close-on-click
    offset-y
    eager
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        icon
        :class="{ 'mr-3': !isMobile, 'mr-2': isMobile }"
      >
        <v-badge
          color="red"
          overlap
          :content="notLookedNotifications"
          :value="notLookedNotifications"
        >
          <v-icon size="28px">{{ $icons.BELL }}</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card :loading="loading" :disabled="loading">
      <v-row
        no-gutters
        :class="
          hasNotification
            ? 'pa-2 pt-6 fontContent2c mb-n4'
            : 'pa-2 pt-6 fontContent2c '
        "
      >
        <v-col cols="4">
          <b class="ml-2">Notificações</b>
        </v-col>
        <v-col cols="8">
          <v-row no-gutters class="mb-n2" justify="end">
            <v-col cols="auto">
              <b class="primary--text fontContent2">
                Mostrar apenas não lidas
              </b>
            </v-col>
            <v-col cols="auto" class="fontContent3">
              <v-switch
                v-model="isToShowJustNotLooked"
                color="primary"
                class="mt-n1 ml-2 decrease-2"
                :disabled="loading"
                @click.stop="socketFilterNotifications(isToShowJustNotLooked)"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="hasNotification" no-gutters class="ml-4 mt-n5 mb-1">
        <v-col cols="12" class="fontContent3">
          <span
            class="notifications__mark-all"
            @click.stop="updateAllUserNotificationsToLooked"
          >
            <b>Marcar todas como lidas</b>
          </span>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-card
          v-scroll.self="onScroll"
          width="500px"
          class="overflow-y-auto"
          style="height: 600px"
        >
          <v-col v-if="notificationsToShow.length" cols="12">
            <v-row no-gutters>
              <template v-if="todayNotifications.length">
                <v-col cols="12">
                  <v-card color="#f6f6f6" width="100%" height="25px" outlined>
                    <b class="fontContent2 ml-4">Hoje</b>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <NotificationsList
                    :notifications="todayNotifications"
                    @closeMenu="closeMenu"
                    @updateUserNotificationLookedAt="
                      updateUserNotificationLookedAt
                    "
                  />
                </v-col>
              </template>
              <template v-if="yesterdayNotifications.length">
                <v-col cols="12">
                  <v-card color="#f6f6f6" width="100%" height="25px" outlined>
                    <b class="fontContent2 ml-4">Ontem</b>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <NotificationsList
                    :notifications="yesterdayNotifications"
                    @closeMenu="closeMenu"
                    @updateUserNotificationLookedAt="
                      updateUserNotificationLookedAt
                    "
                  />
                </v-col>
              </template>
              <template v-if="olderNotifications.length">
                <v-col cols="12">
                  <v-card color="#f6f6f6" width="100%" height="25px" outlined>
                    <b class="fontContent2 ml-4">Mais Antigas</b>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <NotificationsList
                    :notifications="olderNotifications"
                    @closeMenu="closeMenu"
                    @updateUserNotificationLookedAt="
                      updateUserNotificationLookedAt
                    "
                  />
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col v-else cols="12">
            <v-card width="500px" max-height="100px" flat>
              <v-row no-gutters>
                <v-col align="center" class="fontContent2 pa-2">
                  <span>Todas as notificações foram lidas.</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-card>
      </v-row>
    </v-card>
  </v-menu>
</template>
<script>
import { User } from "@/request";
import { mapGetters } from "vuex";
import Socket from "@/plugins/socket";

export default {
  components: {
    NotificationsList: () =>
      import("@/components/global/notifications/NotificationsList"),
  },
  data() {
    return {
      height: null,
      socket: null,
      notifications: [],
      notificationsToShow: [],
      isToShowJustNotLooked: false,
      isMenuOpen: false,
      isUpdatingAllNotifications: false,
      loading: false,
      meta: {
        read: 0,
        unread: 0,
      },
      filter: {
        limit: 25,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters("userData", { user: "getUserData" }),
    hasNotification() {
      return this.notificationsToShow.length && !!this.notLookedNotifications;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    todayNotifications() {
      return this.notificationsToShow.filter((item) =>
        this.filterNotificationDate(item.createdAt, "today")
      );
    },
    yesterdayNotifications() {
      return this.notificationsToShow.filter((item) =>
        this.filterNotificationDate(item.createdAt, "yesterday")
      );
    },
    olderNotifications() {
      return this.notificationsToShow.filter((item) =>
        this.filterNotificationDate(item.createdAt, "beforeYesterday")
      );
    },
    total() {
      return this.meta.read + this.meta.unread;
    },
    notLookedNotifications() {
      return this.meta.unread > 99 ? "+99" : this.meta.unread;
    },
    totalPages() {
      return Math.ceil(this.total / this.filter.limit);
    },
  },
  watch: {
    isUpdatingAllNotifications(value) {
      this.loading = value;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.loading = true;
    this.initNotificationService();
  },

  beforeDestroy() {
    if (Socket.io) {
      Socket.io.disconnect();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.height = window.innerHeight;
    },
    initNotificationService() {
      try {
        Socket.io.on("connect", () => {
          this.loading = false;
          if (!this.notifications || !this.notifications?.length) {
            this.socketGetBaseNotifications(this.filter);
          }
        });

        Socket.io.on("last30DaysNotifications", (payload) => {
          const { read, unread, notifications, merged = false } = payload;
          this.meta = { read, unread };

          let receivedNotifications = notifications.map((item) => {
            item.date = this.setNotificationDate(item.createdAt);
            return item;
          });

          this.notifications = merged
            ? [...this.notifications, ...receivedNotifications]
            : receivedNotifications;
          this.notificationsToShow = this.notifications;

          setTimeout(() => (this.loading = false), 500);
        });

        Socket.io.on("notification", (payload) => {
          this.meta.unread += 1;
          payload.date = this.setNotificationDate(payload.createdAt);
          this.notifications.unshift(payload);
          this.changeViewedNotifications();
        });

        Socket.io.on("length", (payload) => {
          this.meta = { ...this.meta, ...payload };
        });

        Socket.io.on("disconnect", (reason) => {
          console.log("[Socket] Disconnect", reason);

          if (reason === "io server disconnect") {
            Socket.io.connect();
          }
        });

        Socket.io.on("connect_error", (e) =>
          console.warn("[Socket] Connect error", e)
        );
      } catch (e) {
        console.warn("[Socket] Error", e);
      }
    },
    async updateUserNotificationLookedAt(payload) {
      try {
        const ret = await User.updateUserNotificationLookedAt(payload);

        if (!ret.error && ret.data) {
          for (let item of this.notifications) {
            if (
              !this.isUpdatingAllNotifications &&
              !payload.notification.includes(item.notification.id)
            )
              continue;

            item.lookedAt = payload.lookedAt;
          }
          if (!this.isUpdatingAllNotifications) {
            this.changeViewedNotifications();
          }
        }
      } catch (e) {}
    },
    async updateAllUserNotificationsToLooked() {
      try {
        this.isUpdatingAllNotifications = true;
        const lookedAt = new Date();

        await this.updateUserNotificationLookedAt({
          readAll: true,
          lookedAt,
        });

        this.changeViewedNotifications();
        this.isUpdatingAllNotifications = false;
      } catch (e) {}
    },
    changeViewedNotifications() {
      if (this.isToShowJustNotLooked)
        this.notificationsToShow = this.notifications.filter(
          (item) => !item.lookedAt
        );
      else this.notificationsToShow = this.notifications;
    },
    filterNotificationDate(item, time) {
      let today = new Date(new Date().toISOString().slice(0, 10)).getTime(),
        yesterday = new Date(
          new Date(new Date().setDate(new Date().getDate() - 1))
            .toISOString()
            .slice(0, 10)
        ).getTime(),
        itemDate = new Date(item).getTime();

      if (time === "today") return itemDate >= today;
      if (time === "yesterday")
        return itemDate < today && itemDate >= yesterday;
      if (time === "beforeYesterday") return itemDate < yesterday;
    },
    setNotificationDate(createdAt) {
      const now = new Date(),
        yesterday = new Date(new Date().setDate(new Date().getDate() - 1)),
        itemDate = new Date(createdAt);

      if (
        this.isSameDate(now, itemDate) ||
        this.isSameDate(yesterday, itemDate)
      ) {
        return `Às ${itemDate.toLocaleTimeString("pt-br").slice(0, 5)}.`;
      } else {
        let itemArray = itemDate.toBRDateHour().split(" ");
        return `Às ${itemArray[1].slice(0, 5)} em ${itemArray[0].slice(
          0,
          10
        )}.`;
      }
    },
    isSameDate(item, toCompare) {
      return (
        item.toISOString().slice(0, 10) === toCompare.toISOString().slice(0, 10)
      );
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    socketGetBaseNotifications(query = {}) {
      this.loading = true;
      Socket.io.emit(
        "getBaseNotifications",
        this.user.distribuitor.id,
        this.user.id,
        query
      );
    },
    socketFilterNotifications(state) {
      this.meta = { read: 0, unread: 0 };
      this.filter = { ...this.filter, page: 1 };
      this.notifications = [];
      this.socketGetBaseNotifications({
        status: state ? "unread" : "all",
        ...this.filter,
      });
    },
    onScroll(e) {
      const { scrollTop, scrollHeight } = e.target;
      const scrollArea = 600;
      const scrollEnd = Math.ceil(scrollHeight) - Math.ceil(scrollTop);

      const notSubmit = [
        scrollEnd > scrollArea,
        this.filter.page >= this.totalPages,
        this.loading,
        this.total === this.notifications.length,
      ];

      if (notSubmit.includes(true)) return;

      this.filter = { ...this.filter, page: this.filter.page + 1 };

      this.socketGetBaseNotifications({
        ...this.filter,
        merged: true,
        status: this.isToShowJustNotLooked ? "unread" : "all",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.notifications__mark-all {
  cursor: pointer;
  color: var(--v-primary-base);
}

.notifications__mark-all:hover {
  text-decoration: underline;
}

.notification-icon:hover {
  color: var(--v-icon-base);
  cursor: pointer;
}

.notification .v-badge__wrapper .v-badge__badge {
  padding: 3px;
}
</style>
