const state = {
  serverPopUps: [],
};

const mutations = {
  SERVER_POPUPS(state, payload) {
    Object.assign(state.serverPopUps, payload);
  },
};

export default {
  mutations,
  state,
};
