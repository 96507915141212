<template>
  <v-app v-if="!loadingTheme" :style="cssRootVars">
    <AppBar v-if="!isOpenRoute" />
    <ButtonOpenCloseNavBar v-if="!isOpenRoute" />
    <NavBar v-if="!isOpenRoute" />
    <Content />

    <cookie-law buttonText="Aceitar" :storageName="storageName">
      <div slot="message">
        O {{ pageName }} utiliza cookies para que você tenha uma melhor
        experiência em nosso site.
      </div>
    </cookie-law>
    <NotifyDialog />
    <LoadingPage />

    <AdvertisingDialog
      v-model="dialogDisplayPopUp"
      :item="popUpToDisplay"
      @close="closePopUp"
    />
  </v-app>
  <v-row v-else justify="center">
    <v-col style="margin-top: 400px">
      <LoadingStyle />
    </v-col>
  </v-row>
</template>

<script>
import AppBar from "@/components/layout/AppBar";
import NavBar from "@/components/layout/NavBar";
import Content from "@/components/layout/Content";

import NotifyDialog from "@/components/global/NotifyDialog.vue";
import LoadingStyle from "@/components/global/LoadingStyle.vue";
import LoadingPage from "@/components/global/LoadingPage.vue";
import { mapGetters, mapState } from "vuex";
import CookieLaw from "vue-cookie-law";
import AdvertisingDialog from "@/components/global/AdvertisingDialog.vue";
import { getAdvertisingPopUpToDisplay } from "@/views/settings/advertising/PopUps/utils.js";
import ButtonOpenCloseNavBar from "@/components/layout/ButtonOpenCloseNavBar.vue";

export default {
  name: "App",
  components: {
    AppBar,
    NavBar,
    Content,
    NotifyDialog,
    LoadingStyle,
    LoadingPage,
    CookieLaw,
    AdvertisingDialog,
    ButtonOpenCloseNavBar,
  },
  data() {
    const location = window.location;
    return {
      storageName: (location.hostname || location.host) + ".cookies:accepted",
      dialogDisplayPopUp: false,
      popUpToDisplay: null,
      lastDisplayedPopUpTimeByRoute: null,
    };
  },
  computed: {
    ...mapState("userData", { loadingTheme: "loadingTheme" }),
    ...mapGetters("userData", { user: "getUserData" }),
    ...mapGetters("theme", {
      theme: "getTheme",
      getMetaDescription: "getMetaDescription",
      pageName: "getTitle",
    }),
    isOpenRoute() {
      return (
        !this.$route.name ||
        (this.$route.meta?.permissions &&
          this.$route.meta.permissions.indexOf("open") !== -1)
      );
    },
    cssRootVars() {
      return this.theme?.companyTheme
        ? {
            "--gradient-angle":
              this.theme.companyTheme?.angleLinearGradient || undefined,

            "--gradient-color-one":
              this.theme.companyTheme?.colorOneLinearGradient || undefined,

            "--gradient-color-two":
              this.theme.companyTheme?.colorTwoLinearGradient || undefined,

            "--dist-primary-color":
              this.theme.companyTheme?.primaryColor || "#4248f1",
          }
        : {};
    },
  },
  created() {
    this.setAdvertisingEventListener();
  },
  async updated() {
    if (this.isOpenRoute && this.lastDisplayedPopUpTimeByRoute) {
      this.lastDisplayedPopUpTimeByRoute = null;
    }
    this.setAdvertisingEventListener();
  },
  methods: {
    setAdvertisingEventListener() {
      if (
        this.user.actions?.advertising &&
        this.user.type === "integrator" &&
        !Object.keys(this.$bus._events).includes("advertising:open")
      ) {
        this.$bus.$on("advertising:open", async (display, newRoute) => {
          if (!display || this.isOpenRoute) return;
          await this.openAdvertisingPopUp(newRoute);
        });
      }
    },
    async openAdvertisingPopUp(newRoute) {
      const routeName = newRoute ?? this.$route.name;
      const now = new Date().getTime();

      if (
        !this.lastDisplayedPopUpTimeByRoute ||
        !this.lastDisplayedPopUpTimeByRoute[routeName] ||
        now - this.lastDisplayedPopUpTimeByRoute[routeName] > 3600000
      ) {
        const selectedPopUp = await getAdvertisingPopUpToDisplay(routeName);

        if (!selectedPopUp) return;

        if (!this.lastDisplayedPopUpTimeByRoute) {
          this.lastDisplayedPopUpTimeByRoute = {};
        }

        this.lastDisplayedPopUpTimeByRoute[routeName] = new Date().getTime();
        this.popUpToDisplay = selectedPopUp;
        this.dialogDisplayPopUp = true;
      }
    },
    closePopUp() {
      this.dialogDisplayPopUp = false;
      this.popUpToDisplay = null;
    },
  },
};
</script>

<style lang="scss">
@import "./scss/root-reset.scss";
@import "./scss/vuetify.scss";
@import "./scss/app.scss";
@import "./scss/font.scss";
@import "./scss/switch.scss";
@import "./scss/table.scss";
@import "./scss/text.scss";
@import "./scss/nav-bar.scss";
</style>
