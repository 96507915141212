import store from "@/store";
import socket from "@/plugins/socket";

export class RecommendationCalculation {
  static async calculateRecommendations() {
    const products = store.getters["storeFlow/products/itemsCheckout"]
      .filter(
        (item) =>
          (item.amount > 0 || item.recAmount > 0) &&
          [
            "module",
            "inverter",
            "stringBox",
            "complementaryEquipment",
          ].includes(item.type)
      )
      .map((item) => item.toRecommendationObject());
    const structure = store.getters["storeFlow/products/structure"];
    if (!products.length) return [];

    return socket.promisefy("calculateDynamicRecommendations", {
      products,
      structure,
    });
  }
}
