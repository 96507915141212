import { User } from "@/request";
import {
  isSizingOrProposalCRMRoute,
  checkIsUserAllowedEditRequest,
  checkPermissions,
  prepareDuplicationRequest,
} from "@/utils/utils";
import checkout from "@/store/modules/storeFlow/checkout";
import products from "@/store/modules/storeFlow/products";
import router from "@/router";
import { RESULT_SIZING, INFO_REQUEST } from "@/localStorage/storageKeys";
import { Storage } from "@/localStorage";
import {
  PURCHASE_TYPES,
  PURCHASE_TYPE_CLOSED_KIT,
  PURCHASE_TYPE_CUSTOM_KIT,
  PURCHASE_TYPE_KIT_EXPRESS,
  PURCHASE_TYPE_SEPARATED_SALE,
} from "@/utils/constants";

const formatPayment = (payment) => {
  if (payment?.condition?._id) {
    payment.condition = payment.condition._id;
  }
  return payment;
};

const getDefaultState = () => {
  return {
    loading: false,
    purchaseType: "", // separatedSales || mountKits || closedKits
    integrator: "",
    pastInfo: null,
    editType: "",
    dialogBeforePurchase: false,
    alterPowerFilter: false,
    duplicateRequestProcess: false,
    integratorInfo: {
      companyName: "",
      email: "",
      cnpj: "",
      cpf: "",
      telephone: "",
      ie: "",
      defaultDiscount: 0,
      integratorAddress: null,
    },
    msgs: {
      closedKits: {
        always: "",
        ifShowPrice: "",
      },
      kitExpress: {
        always: "",
        ifShowPrice: "",
      },
      mountKits: {
        modules: {
          always: "",
          ifShowPrice: "",
        },
        inverters: {
          always: "",
          ifShowPrice: "",
        },
        stringBoxes: {
          always: "",
          ifShowPrice: "",
        },
        structures: {
          always: "",
          ifShowPrice: "",
        },
        others: {
          always: "",
          ifShowPrice: "",
        },
        confirmation: {
          always: "",
          ifShowPrice: "",
        },
      },
      separatedSales: {
        modules: {
          always: "",
          ifShowPrice: "",
        },
        inverters: {
          always: "",
          ifShowPrice: "",
        },
        stringBoxes: {
          always: "",
          ifShowPrice: "",
        },
        structures: {
          always: "",
          ifShowPrice: "",
        },
        others: {
          always: "",
          ifShowPrice: "",
        },
      },
      checkout: {
        freight: "",
        invoice: "",
        onCIF: "",
        onFOB: "",
      },
    },
  };
};
const state = getDefaultState();

const mutations = {
  setPurchaseType(state, payload) {
    state.purchaseType = payload.purchaseType;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIntegrator(state, payload) {
    state.integrator = payload.integrator;
  },
  setMsgs(state, payload) {
    state.msgs = payload.msgs;
  },
  setEditType(state, payload) {
    state.editType = payload.editType;
  },
  setPastInfo(state, payload) {
    state.pastInfo = payload.pastInfo;
  },
  setDuplicateRequestProcess(state, payload) {
    state.duplicateRequestProcess = payload;
  },
  SET_DIALOG_BEFORE_PURCHASE(state, payload) {
    state.dialogBeforePurchase = payload;
  },
  SET_ALTER_POWER_FILTER(state, payload) {
    state.alterPowerFilter = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIntegratorInfo(state, payload) {
    state.integratorInfo = payload.integratorInfo;
  },
};

const actions = {
  async firstLoad(
    { dispatch, commit, rootGetters },
    { purchaseType, updateExpiredBudget = false, customEvent = "" }
  ) {
    const request = rootGetters["requestEdit/editRequest"];
    const ecommerce = request?.ecommerce || false;
    const reloadRequest =
      request?.id === router.history?.current?.params?.id ||
      customEvent ||
      updateExpiredBudget;

    if (!request.isCopy) {
      await dispatch("baseLoad", {
        purchaseType,
        reloadRequest,
        customEvent,
        ecommerce,
      });
    }

    await dispatch("readMessages");
    await dispatch("checkout/checkoutLoad");

    if (customEvent) {
      commit("checkout/setEventType", { eventType: customEvent });
    }

    if (reloadRequest || request.isCopy) {
      await dispatch("reloadRequest", request);
    }
  },
  async baseLoad(
    { dispatch, commit, getters },
    { purchaseType, customEvent, ecommerce, duplicateRequestProcess = false }
  ) {
    commit("resetState");
    commit("setPurchaseType", { purchaseType });
    await dispatch("products/baseLoad", {
      purchaseType,
      eventType: customEvent,
      ecommerce,
    });

    if (!getters.isStaff) {
      await dispatch("getIntegratorState");
    }

    commit("setDuplicateRequestProcess", duplicateRequestProcess);
    commit("SET_DIALOG_BEFORE_PURCHASE", true);
  },
  async getIntegratorState({ dispatch, getters }) {
    const ret = await User.getIntegratorState(getters.integrator || "");
    if (ret.data && typeof ret.data === "string")
      dispatch("changeDeliveryState", ret.data);
  },
  async readMessages({ commit }) {
    const ret = await User.readMessages("all");
    if (!ret.error && ret.data?.messages) {
      commit("setMsgs", { msgs: ret.data.messages });
    }
  },
  async changeDeliveryState({ dispatch, commit, getters, rootGetters }, state) {
    commit("checkout/setAdditionalInfoData", { deliveryState: state });
    await dispatch("products/recalculatePrices");
    await dispatch("checkout/recalculateExtraItemsPrices");
  },
  async setIntegrator({ dispatch, commit, getters, rootGetters }, payload) {
    let integrator = payload.id;
    commit("setIntegrator", { integrator });
    await dispatch("getIntegratorState");
    await dispatch("checkout/readIntegratorInfo");
    await dispatch("products/recalculatePrices");
  },
  setDialogBeforePurchase({ commit }, payload) {
    commit("SET_DIALOG_BEFORE_PURCHASE", payload);
  },
  setAlterPowerFilter({ commit, state }, payload) {
    commit("SET_ALTER_POWER_FILTER", payload);
  },
  async cancelEdit({ getters }) {
    await router.push({
      name: getters.editType === "order" ? "Requests" : "Budgets",
    });
  },
  async reloadRequest({ dispatch, commit, getters, rootGetters }, request) {
    let products =
      request.products.map((item) => {
        return { ...item };
      }) || [];

    let additionalInfo = { ...request.additionalInfo };
    if (additionalInfo.invoiceValue)
      additionalInfo.invoiceValue = additionalInfo.invoiceValue
        .convertToNumber()
        .toFixed(2);
    if (additionalInfo.companyServiceValue)
      additionalInfo.companyServiceValue = additionalInfo.companyServiceValue
        .convertToNumber()
        .toFixed(2);
    if (request.structure) commit("products/setStructure", request.structure);

    const pastInfo = {
      type: request.type,
      name: request.name,
      additionalInfo: request.additionalInfo,
      id: request.id,
      extraItems: products.filter((p) => p && p.type === "extraItem"),
      products,
      coupon: request.coupon,
    };
    const freight = { ...request.freight };
    const isDuplicateRequestProcess = getters.isDuplicateRequestProcess;

    if (isDuplicateRequestProcess) {
      const state =
        rootGetters["requestEdit/editRequest"]?.additionalInfo?.deliveryState ||
        null;
      Object.assign(additionalInfo, {
        shippingAddress: null,
        sameAddress: null,
        timeRestrictionBoolean: null,
        timeRestriction: "",
        truckPossible: null,
        ruralZone: "",
        deliveryState: state,
      });
      Object.assign(freight, {
        state: state || "N/A",
        price: 0,
        timeToDeliver: 0,
      });
    }

    if (freight) {
      commit("checkout/setFreight", { freight });
    }
    commit("setPastInfo", { pastInfo });
    commit("setEditType", { editType: request.type });
    commit("checkout/setRequestName", { requestName: request.name });
    commit("checkout/setValueModifier", request.valueModifier || {});
    commit("checkout/setAdditionalInfoData", additionalInfo);
    if (additionalInfo?.cpf)
      commit("checkout/setTipoPessoaFisicaOuJuridica", {
        tipoPessoaFisicaOuJuridica: "física",
      });
    if (additionalInfo?.cnpj)
      commit("checkout/setTipoPessoaFisicaOuJuridica", {
        tipoPessoaFisicaOuJuridica: "jurídica",
      });
    commit("checkout/setPastAnswersCustomForm", {
      pastAnswersCustomForm: additionalInfo.answersCustomForm || [],
    });
    if (additionalInfo.changeInvoice) {
      commit("checkout/setSelectedOptionInvoiceValue", {
        selectedOptionInvoiceValue: !!additionalInfo.companyServiceValue,
      });
    }

    products
      .filter((p) => p && p.type === "extraItem")
      .forEach((extraItem) => {
        const disponibleExtraItem = getters["checkout/extraItems"].find(
          (prod) => prod.id === extraItem.id
        );
        if (disponibleExtraItem) {
          commit("checkout/setExtraItemAmount", {
            item: disponibleExtraItem,
            amount: 1,
            basePrice: extraItem.basePrice,
          });
        } else {
          commit("checkout/pushExtraItem", extraItem);
        }
      });

    commit("checkout/setPaymentData", request.payment);
    const allProducts = getters["products/allProducts"],
      missingProducts = [];
    for (let product of products) {
      let disponibleProduct = allProducts.filter(
        (p) => p.id === product.id
      )?.[0];

      if (disponibleProduct) {
        dispatch("products/setProductAmount", {
          item: disponibleProduct,
          amount: product.amount,
          recAutomaticAmount: product.recAutomaticAmount,
          recAmount: product.recAmount,
          freeAmount: product.freeAmount,
        });
        commit("products/setProductPrice", {
          item: disponibleProduct,
          price: product.singlePrice || disponibleProduct.price,
        });
      } else if (
        getters.editType !== "budget" ||
        getters["checkout/eventType"] === "budgetToOrder"
      ) {
        if (
          [
            "module",
            "inverter",
            "stringBox",
            "complementaryEquipment",
          ].includes(product.type)
        ) {
          commit("products/pushProduct", {
            ...product,
            price: product.basePrice || product.price,
          });
        }
      } else {
        if (product.type === "extraItem") {
          const extraItems = getters["checkout/extraItems"];
          if (!extraItems.find((extraItem) => extraItem.id === product.id))
            missingProducts.push(product.name);
        } else if (product.amount > 0) {
          missingProducts.push(product.name);
        }
      }
    }

    if (missingProducts.length) {
      commit("products/setMissingProducts", missingProducts);
    } else {
      commit("products/setMissingProducts", []);
    }
    if (getters.isStaff)
      commit("setIntegrator", { integrator: request.company });
    await dispatch("checkout/readIntegratorInfo");

    const integratorAddress = getters["checkout/integratorAddress"];
    const requestState = isDuplicateRequestProcess
      ? request.additionalInfo.deliveryState
      : request.additionalInfo?.shippingAddress?.state &&
        request.additionalInfo?.shippingDistributor
      ? request.additionalInfo.shippingAddress.state
      : integratorAddress.state;
    await dispatch("changeDeliveryState", requestState);
  },

  async saveRequests({ dispatch, getters, state }) {
    const eventType = getters["checkout/eventType"];

    if (!state.checkout.additionalInfo?.shippingDistributor) {
      await dispatch("checkout/calculateFreight");
    }

    if (eventType === "order" || eventType === "budget") {
      await dispatch("createRequest", {
        requestType: eventType,
        updateExpiredBudget: false,
      });
    }

    if (eventType === "budgetToOrder") {
      await dispatch("finishConvertBudgetToOrder");
    }

    if (eventType === "editFreight") {
      await dispatch("finishFreightEdition");
    }
  },

  async updateExpiredBudget(context, payload) {
    const requestInfo = await User.readRequestById({ requestId: payload.id });
    if (
      !requestInfo.error &&
      requestInfo.data.kind !== PURCHASE_TYPE_CLOSED_KIT
    ) {
      context.commit("requestEdit/SAVE_EDIT_REQUEST", requestInfo.data, {
        root: true,
      });
      const { purchaseType } = context.rootGetters["requestEdit/editRequest"];
      await context.dispatch("firstLoad", {
        purchaseType,
        updateExpiredBudget: true,
      });

      const missingProductsMessage =
        context.getters["products/missingProductsMessage"];
      if (missingProductsMessage.length) {
        context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
        context.commit("products/setMissingProducts", []);
        await context.dispatch(
          "NotifyDialog/openNotifyWarning",
          {
            title: "Atenção",
            message: missingProductsMessage,
          },
          { root: true }
        );
      } else
        return context.dispatch("createRequest", {
          requestType: "budget",
          updateExpiredBudget: true,
        });
    }
  },

  async createRequest({ dispatch, commit, getters, rootGetters }, payload) {
    commit("checkout/setActiveForm", { activeForm: false });
    const payment = getters["checkout/payment"];
    const coupon = getters["checkout/coupon"];
    const data = {
      name: getters["checkout/requestName"],
      kits: getters["products/kitsCheckout"].map((item) => ({
        ...item.toRequestObject(),
      })),
      power: getters["products/totalModulesPower"],
      products: getters["products/itemsCheckout"].map((item) => ({
        ...item.toRequestObject(),
      })),
      structure: getters["products/structure"],
      additionalInfo: getters["checkout/additionalInfo"],
      freight: getters["checkout/freight"],
      purchaseType: getters.purchaseType,
      extraItems: getters["checkout/extraItemsSelected"],
      checkoutFile: getters["checkout/checkoutFile"],
      editId: getters.editId,
      valueModifier: getters["checkout/valueModifier"],
      payment: payment?.type
        ? formatPayment(Object.assign({}, payment))
        : undefined,
      coupon: coupon?.code,
    };

    const deletedRecommendedProducts = getters[
      "products/deletedRecommendedProducts"
    ].map((item) => ({ ...item.toRequestObject() }));
    data.products.push(...deletedRecommendedProducts);

    if (isSizingOrProposalCRMRoute()) {
      let resultSizing = await Storage.getItem(RESULT_SIZING);
      Object.assign(data, {
        sizingId: resultSizing?.id || undefined,
        leadId: router.history?.current?.params?.leadId || undefined,
        systemId: router.history?.current?.params?.systemId || undefined,
      });
    }

    const request = rootGetters["requestEdit/editRequest"];
    if (payload.updateExpiredBudget) {
      Object.assign(data, {
        sizingId: request.sizingId || undefined,
        leadId: request.leadId || undefined,
        systemId: request.systemId || undefined,
        editId: request.id,
        updateExpiredBudget: new Date(),
      });
    }
    const ret = await User.createRequest(
      payload.requestType,
      getters.integrator || request.integrator,
      data
    );

    if (!ret.error && ret.data) {
      if (getters["checkout/uploadedFiles"].length) {
        await User.importRequestFiles(
          ret.data.id,
          getters["checkout/uploadedFiles"]
        );
        commit("checkout/setUploadedFiles", { uploadedFiles: [] });
      }

      if (payload.updateExpiredBudget) {
        commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
        return true;
      } else {
        await Storage.setItem(INFO_REQUEST, ret.data);
        commit("checkout/setActiveDialogRoutes", { activeDialogRoutes: true });
        commit(
          "requestEdit/SAVE_EDIT_REQUEST",
          { ...ret.data, isCopy: request.isCopy },
          {
            root: true,
          }
        );
      }
    }
  },

  async startModifyRequest(context, payload) {
    if (await checkIsUserAllowedEditRequest(payload.requestDate)) {
      const requestInfo = await User.readRequestById({ requestId: payload.id });
      if (!requestInfo.error) {
        context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
        context.commit("requestEdit/SAVE_EDIT_REQUEST", requestInfo.data, {
          root: true,
        });

        const deleteEditRequest = () => {
            context.commit(
              "requestEdit/DELETE_EDIT_REQUEST",
              {},
              { root: true }
            );
          },
          redirectToModifyRequest = ({ purchaseType, id }) => {
            const pageRedirect = {
              [PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]]: "MountKit",
              [PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE]]: "Separate",
              [PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]]: "KitsIntegrator",
              [PURCHASE_TYPES[PURCHASE_TYPE_KIT_EXPRESS]]: "KitsExpress",
            };
            if (pageRedirect[purchaseType] && id) {
              router.push({ name: pageRedirect[purchaseType], params: { id } });
            } else {
              deleteEditRequest();
              context.dispatch(
                "NotifyDialog/openNotifyError",
                {
                  title: "Atenção",
                  message: `Erro ao obter os dados do ${
                    payload.type === "order" ? "pedido" : "orçamento"
                  }.`,
                },
                { root: true }
              );
            }
          },
          { purchaseType, id } = context.rootGetters["requestEdit/editRequest"],
          nullItemProducts = requestInfo?.data?.products
            ?.filter((item) => item.item === null && item.amount > 0)
            .map((item) => item.name);

        if (nullItemProducts.length) {
          const message = `${
            nullItemProducts.length > 1
              ? "Os equipamentos a seguir não estão mais disponíveis"
              : "O equipamento a seguir não está mais disponível"
          }:
            ${nullItemProducts
              .map((el, idx, arr) =>
                idx === arr.length - 1 ? `- ${el};` : `- ${el};\n`
              )
              .join(" ")}
            Deseja prosseguir selecionando substitutos para
            ${
              nullItemProducts.length > 1
                ? "esses equipamentos"
                : "esse equipamento"
            }?`;
          await context.dispatch(
            "NotifyDialog/openNotifyError",
            {
              cancelLabel: "Não",
              confirmLabel: "Sim",
              title: "Atenção",
              message: message,
              onCancel: () => deleteEditRequest(),
              onConfirm: () => redirectToModifyRequest({ purchaseType, id }),
            },
            { root: true }
          );
        } else {
          redirectToModifyRequest({ purchaseType, id });
        }
      }
    }
  },
  async duplicateRequest(context) {
    const request = context.rootGetters["requestEdit/editRequest"];
    const stateForCalculation =
      context.getters["checkout/additionalInfo"]?.deliveryState;
    const integrator = context.getters["integrator"];
    const ret = await User.checkStock(
      stateForCalculation,
      request.products.map((item) => ({
        id: item.id,
        amount: item.amount,
        center: item.center?._id,
      })),
      [],
      request.type === "budget"
    );

    if (ret.error) {
      context.commit("resetState");
      context.commit("setDuplicateRequestProcess", false);
      context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
      await context.dispatch(
        "NotifyDialog/openNotifyWarning",
        {
          title: "Equipamentos indisponíveis neste orçamento!",
          message:
            "Não é possível duplicar este orçamento, pois alguns equipamentos não estão mais disponíveis.",
        },
        { root: true }
      );
      return;
    }

    const additionalInfo = Object.assign(
      {},
      {
        ...request.additionalInfo,
        deliveryState: stateForCalculation,
      }
    );
    context.commit("requestEdit/SET_ADDITIONAL_INFO", additionalInfo, {
      root: true,
    });
    context.commit("requestEdit/SET_INTEGRATOR", integrator, {
      root: true,
    });

    const redirectToCompleteCopy = (purchaseType) => {
      const pageRedirect = {
        [PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]]: "MountKit",
        [PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE]]: "Separate",
        [PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]]: "KitsIntegrator",
        [PURCHASE_TYPES[PURCHASE_TYPE_KIT_EXPRESS]]: "KitsExpress",
      };
      router.push({ name: pageRedirect[purchaseType] });
    };

    redirectToCompleteCopy(request.purchaseType);
  },
  async startDuplication(context, payload) {
    const { id, type, kind } = payload;
    const request = await User.readRequestById({
      requestId: id,
      query: { type },
    });
    if (!request.error) {
      context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
      context.commit(
        "requestEdit/SAVE_EDIT_REQUEST",
        prepareDuplicationRequest(request.data),
        {
          root: true,
        }
      );
      const customEvent = type;
      await context.dispatch("baseLoad", {
        purchaseType: PURCHASE_TYPES[kind],
        customEvent,
        duplicateRequestProcess: true,
      });
    }
  },

  async startConvertBudgetToOrder(context, payload) {
    const budget = await User.readRequestById({
      requestId: payload.id,
      query: { budgetToOrder: 1 },
    });
    if (!budget.error) {
      context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
      context.commit("requestEdit/SAVE_EDIT_REQUEST", budget.data, {
        root: true,
      });
      const { purchaseType } = context.rootGetters["requestEdit/editRequest"];
      const customEvent = "budgetToOrder";
      await context.dispatch("firstLoad", {
        purchaseType,
        customEvent,
      });
      await context.dispatch("checkout/openDialogSaveName", customEvent);
    }
  },

  async startEditFreight(context, payload) {
    const request = await User.readRequestById({
      requestId: payload.id,
      query: { editFreight: 1 },
    });
    if (!request.error) {
      context.commit("requestEdit/DELETE_EDIT_REQUEST", {}, { root: true });
      context.commit("requestEdit/SAVE_EDIT_REQUEST", request.data, {
        root: true,
      });
      const { purchaseType } = context.rootGetters["requestEdit/editRequest"];
      const customEvent = "editFreight";
      await context.dispatch("firstLoad", {
        purchaseType,
        customEvent,
      });
      await context.dispatch("checkout/openDialogSaveName", customEvent);
    }
  },

  async finishConvertBudgetToOrder({ commit, getters, rootState }) {
    commit("checkout/setActiveForm", { activeForm: false });

    const payment = getters["checkout/payment"];
    const coupon = getters["checkout/coupon"];

    let ret = await User.createOrderFromBudget(getters.pastInfo.id, {
      additionalInfo: getters["checkout/additionalInfo"],
      freight: getters["checkout/freight"],
      payment: payment?.type ? formatPayment({ ...payment }) : undefined,
      coupon: coupon?.code,
      kind: getters.purchaseType,
    });

    let readDetails = checkPermissions(
      ["order.details"],
      ["order.details"],
      rootState.userData
    );

    if (!ret.error && getters["checkout/uploadedFiles"].length) {
      await User.importRequestFiles(
        ret.data.id,
        getters["checkout/uploadedFiles"]
      );
      commit("checkout/setUploadedFiles", { uploadedFiles: [] });
    }

    if (
      !ret.error &&
      readDetails &&
      router.history.current.name === "BudgetsDetails" &&
      ret.data
    ) {
      commit("requestEdit/SAVE_EDIT_REQUEST", ret.data, { root: true });

      router.push({
        name: "RequestsDetails",
        params: { requestId: ret.data.id },
      });
    }

    if (!ret.error && router.history.current.name === "Budgets")
      router.push({ name: "Requests" });
  },

  async finishFreightEdition({ commit, getters }) {
    commit("checkout/setActiveForm", { activeForm: false });
    return await User.editRequestFreight(getters.pastInfo.id, {
      additionalInfo: getters["checkout/additionalInfo"],
      freight: getters["checkout/freight"],
    });
  },

  setDuplicateRequestProcess({ commit }, payload = false) {
    commit("setDuplicateRequestProcess", payload);
  },
};

const getters = {
  loading: (state) => state.loading,
  purchaseType: (state) => state.purchaseType,
  state: (state) => state.state,
  type: (state, getters, rootState) => rootState.userData?.type,
  isStaff: (state, getters) => ["staff", "admin"].includes(getters.type),
  showPrice: (state, getters, rootState) => {
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_SEPARATED_SALE])
      return (
        rootState.integratorPermissions?.howToDisplayPricesInSeparateSale ===
        "onSelection"
      );
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT])
      return (
        rootState.integratorPermissions?.howToDisplayPricesInClosedKits ===
        "onSelection"
      );
    return false;
  },
  showProductPrice: (state, getters, rootState) => {
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]) {
      return rootState.integratorPermissions
        ?.howToDisplayProductsPricesInMountKit;
    }
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_KIT_EXPRESS]) {
      return rootState.integratorPermissions
        ?.howToDisplayProductsPricesInKitExpress;
    }
    return "never";
  },
  showKitPrice: (state, getters, rootState) => {
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]) {
      return (
        rootState.integratorPermissions?.howToDisplayKitPriceInMountKit ===
        "onConfirmation"
      );
    }
    if (getters.purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_KIT_EXPRESS]) {
      return (
        rootState.integratorPermissions?.howToDisplayKitPriceInKitExpress ===
        "onSelection"
      );
    }
    return false;
  },
  msgs: (state) => state.msgs,
  separateMessages: (state) => state.msgs.separatedSales,
  editType: (state) => state.editType,
  integrator: (state) => state.integrator,
  pastInfo: (state) => state.pastInfo,
  isDuplicateRequestProcess: (state) => state.duplicateRequestProcess,
  pastCoupon: (state) => state.pastInfo?.coupon?.code || "",
  editId: (state) =>
    state.pastInfo?.id ? router.history?.current.params.id : "",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    checkout,
    products,
  },
};
