import Icons from "@/constants/Icons";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      icon: Icons.VIEWDASHBOARD,
      title: "Visão Geral",
      local: ["navBar"],
      permissions: ["admin", "staff"],
    },
    component: () => import("@/views/dashboard/Dashboard.vue"),
  },
  {
    path: "/dashboard-bi",
    name: "DashboardBI",
    meta: {
      isBeta: true,
      icon: Icons.CHARTBOXOUTLINE,
      title: "Dashboard BI",
      local: ["navBar"],
      permissions: ["admin"],
    },
    component: () => import("@/views/dashboard/PageDashboardBI.vue"),
  },
  {
    path: "/dashboardIntegrator",
    name: "DashboardIntegrator",
    meta: {
      icon: Icons.VIEWDASHBOARD,
      title: "Visão Geral",
      local: ["navBar"],
      permissions: ["integrator"],
    },
    component: () => import("@/views/dashboard/Dashboard.vue"),
  },

  // ##########################
  //       ACOMPANHAMENTO
  // ##########################
  {
    path: "/viewedKits",
    name: "ViewedKits",
    meta: {
      icon: Icons.ARCHIVEEYE,
      title: "Kits Pesquisados",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["viewedKits"],
    },
    component: () => import("@/views/viewedKits/ViewedKits.vue"),
  },
  {
    path: "/budgets",
    name: "Budgets",
    meta: {
      icon: Icons.TEXTBOXSEARCH,
      title: "Orçamentos",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["budget"],
      staffPermissions: ["budget"],
    },
    component: () => import("@/views/budgets/PageBudgets.vue"),
    children: [
      {
        path: "/budgets/detail/:requestId",
        name: "BudgetsDetails",
        meta: {
          title: "Detalhes Orçamento",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          staffPermissions: ["budget.details"],
        },
        component: () =>
          import(
            "@/components/plataform/commonRequestComponents/DetailsScreen"
          ),
      },
    ],
  },
  {
    path: "/requests",
    name: "Requests",
    meta: {
      type: "order",
      icon: Icons.TEXTBOXCHECK,
      title: "Pedidos",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["order"],
      staffPermissions: ["order"],
    },
    component: () => import("@/views/requests/PageRequests.vue"),
    children: [
      {
        path: "/requests/detail/:requestId",
        name: "RequestsDetails",
        meta: {
          title: "Detalhes Pedido",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          staffPermissions: ["order.details"],
        },
        component: () =>
          import(
            "@/components/plataform/commonRequestComponents/DetailsScreen"
          ),
      },
    ],
  },
  {
    path: "/integrators",
    name: "Integrators",
    meta: {
      icon: Icons.INTEGRATOR,
      title: "Empresas de Energia",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["integrators.read"],
      onePermissionNeeded: true,
    },
    component: () => import("@/views/integrators/PageIntegrators.vue"),
    children: [
      {
        path: "/integrators/detail/:integratorId",
        name: "IntegratorDetails",
        meta: {
          title: "Detalhe integrador",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["integrators.details"],
        },
        component: () =>
          import("@/views/integrators/PageIntegratorDetails.vue"),
      },
    ],
  },
  {
    path: "/sellers",
    name: "Sellers",
    meta: {
      icon: Icons.ACCOUNTGROUP,
      title: "Colaboradores",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["staff"],
    },
    component: () => import("@/views/sellers/PageSellers.vue"),
    children: [
      {
        path: "/sellers/detail/:sellerId",
        name: "SellerDetails",
        meta: {
          title: "Detalhe colaboradores",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["staff.details"],
        },
        component: () => import("@/views/sellers/PageSellerDetails.vue"),
      },
    ],
  },
];
