import { Integrator } from "@/request";

const state = {
  sellerPermissions: {
    authSellerComplementaryValues: false,
    authSellerProfitMagin: false,
    authSellerDiscount: false,
    authSellerMaxDiscount: 0,
    authSellerViewRequestsBudgetsPrices: true,
  },
};

const mutations = {
  READ_COMPANY_PERMISSIONS(state, payload) {
    Object.assign(state.sellerPermissions, payload);
  },
};

const actions = {
  async readPermissions(context) {
    const user = context.rootGetters["userData/getUserData"];
    if (!user) return;
    if (user.type === "integrator" && user.integratorRole === "seller") {
      const ret = await Integrator.readProposalSettings("permissions");
      if (!ret.error && ret.data)
        context.commit("READ_COMPANY_PERMISSIONS", ret.data[0].permissions);
    }
  },
};

const getters = {
  getAuthSellerComplementaryValues: (state) =>
    state.sellerPermissions.authSellerComplementaryValues,
  getAuthSellerProfitMargin: (state) =>
    state.sellerPermissions.authSellerProfitMagin,
  getAuthSellerDiscount: (state) => state.sellerPermissions.authSellerDiscount,
  getAuthSellerMaxDiscount: (state) =>
    state.sellerPermissions.authSellerMaxDiscount,
  getAuthSellerIntegratorViewRequestsBudgetsPrices: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const user = rootGetters["userData/getUserData"];
    if (!user) return;
    if (user.type !== "integrator" || user.integratorRole !== "seller") {
      return true;
    }
    return state.sellerPermissions.authSellerViewRequestsBudgetsPrices ?? true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
