import store from "@/store";
import { cloneDeep } from "lodash";
import { ProductEntity } from "./ProductEntity";
import { toCurrency } from "@/utils/CurrencyUtils";

export class KitEntity {
  price = 0;
  amount = 0;
  center = null;
  kit = {};

  constructor(kit) {
    this.kit = kit;
    this.price = kit.price;
  }

  get basePrice() {
    return this.kit.price;
  }

  get singlePrice() {
    return this.price + this.structurePrice;
  }

  get isPromotional() {
    return this.kit.isPromotional;
  }

  get formattedSinglePrice() {
    return toCurrency(this.singlePrice);
  }

  get totalPrice() {
    return this.singlePrice * this.amount;
  }

  get formattedTotalPrice() {
    return toCurrency(this.totalPrice);
  }

  get type() {
    return "kit";
  }

  get mainStructure() {
    return this.kit.structure?.[0] || {};
  }

  get structureName() {
    return this.mainStructure.name;
  }

  get structureId() {
    return this.mainStructure.id;
  }

  get structurePrice() {
    return this.mainStructure.price || 0;
  }

  get centerName() {
    return (
      store.getters["storeFlow/products/regionsEntity"]?.getCenter(this.center)
        ?.name || "indefinido"
    );
  }

  toRequestObject() {
    const products = this.products.map((item) => item.toRequestObject());

    this.products.forEach((product) => {
      product.freeAmount = product.amount;
      product.recAmount = product.amount;
    });

    return {
      id: this.id,
      name: this.name,
      productCode: this.productCode,
      price: this.price,
      basePrice: this.basePrice,
      power: this.power,
      amount: this.amount,
      description: this.description,
      products,
      totalPrice: this.totalPrice,
      structure: this.structure,
      singlePrice: this.singlePrice,
    };
  }

  // KitInfo

  get id() {
    return this.kit.id;
  }

  get name() {
    return this.kit.name;
  }

  get productCode() {
    return this.kit.productCode;
  }

  get description() {
    return this.kit.description;
  }

  get power() {
    return this.kit.power;
  }

  get structure() {
    return this.kit.structure;
  }

  get products() {
    return cloneDeep(this.kit.products) || [];
  }

  get modules() {
    return this.products.filter((p) => p.type === "module") || [];
  }

  get inverters() {
    return this.products.filter((p) => p.type === "inverter") || [];
  }

  get stringBoxes() {
    return this.products.filter((p) => p.type === "stringBox") || [];
  }

  get structureProducts() {
    return (
      this.products.filter(
        (p) =>
          p.type === "complementaryEquipment" &&
          p.category?.type === "structure"
      ) || []
    );
  }

  get otherProducts() {
    return (
      this.products.filter(
        (p) =>
          p.type === "complementaryEquipment" && p.category?.type === "others"
      ) || []
    );
  }

  static getChunkedKits(retKits = []) {
    return retKits
      .map((item) => {
        if (item.products?.length) {
          item.products = item.products.map((p) => {
            return new ProductEntity(Object.assign(p, { ...(p.item || {}) }));
          });
        }

        if (item.structure?.length) {
          item.structure = item.structure.map((s) => {
            Object.assign(s, { ...(s.item || {}) }, { amount: 0 });
            delete s.item;
            return s;
          });
        }

        return item;
      })
      .map((kit) => {
        const { structure = [] } = kit;
        return structure.map(
          (st) =>
            new KitEntity({
              ...kit,
              structure: [st],
            })
        );
      })
      .flat();
  }

  setAvailableCenter(centerOrder = []) {
    for (const center of centerOrder) {
      if (!this.isAvailableInCenter(center)) continue;
      this.center = center;
      this.products.forEach((product) => {
        product.center = center;
      });
      break;
    }
  }

  isAvailableInCenter(center) {
    return this.products.every((product) =>
      product.isAvailableInCenter(center)
    );
  }

  get formattedPriceByKWp() {
    if (this.singlePrice && this.power) {
      return (this.singlePrice / this.power).toBRNumber();
    }
  }

  get formattedPower() {
    if (this.power) {
      return `${this.power.toBRNumber()} kWp`;
    }
  }

  get photos() {
    return [...(this.modules || []), ...(this.inverters || [])]
      .map((p) => p.photo)
      .filter((photo) => !!photo);
  }
}
